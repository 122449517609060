/* 源ノ角ゴシック*/
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/NotoSansJP-Regular.otf') format('opentype');
}
/* Google Material Icon*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(fonts/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
    local('MaterialIconsOutlined-Regular'),
    url(fonts/iconfont/MaterialIconsOutlined-Regular.otf) format('opentype');
}
[class^='material-icons'] {
  font-weight: normal;
  font-style: normal;
  font-size: 2.0rem;
  /* Support for all WebKit browsers. */
  /* Support for Safari and Chrome. */
  /* Support for Firefox. */
  /* Support for IE. */
}
/* Preferred icon size */
[class^='material-icons'].sm {
    font-size: 1.3rem;
  }
[class^='material-icons'].md {
    font-size: 1.5rem;
  }
[class^='material-icons'].lg {
    font-size: 2.5rem;
  }
[class^='material-icons'].xxlg {
    font-size: 5.0rem;
  }
[class^='material-icons'].blue {
    color: rgb(0, 94, 184);
  }
[class^='material-icons'] {
  display: -webkit-inline-flex;
  display: inline-flex;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}
.material-icons {
  font-family: 'Material Icons';
}
.material-icons-outlined {
    font-family: 'Material Icons Outlined';
  }
/*///////////////////////////////////////////////////*/
body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.0rem;
}
.appInner {
  color: rgba(33, 37, 41, 1);
  background-color: rgba(247, 247, 250, 1);
  /* > div {
    overflow: auto;
  } */
}
.main {
  /* IE11の場合＋40px位必要*/
  height: calc(100vh - 85px);
}
.main.full {
    height: calc(100vh - 35px);
  }
/* IE11*/
_:-ms-lang(x)::-ms-backdrop, .main {
  height: calc(100vh - 125px);
}
_:-ms-lang(x)::-ms-backdrop.full, .main.full {
    height: calc(100vh - 75px);
  }
.row {
  width: 100vw;
  margin: 0px 0px !important;
}
.height-max-content {
  height: -webkit-max-content !important;
  height: max-content !important;
}
.width-max-content {
  width: -webkit-max-content !important;
  width: max-content !important;
}
.width-100 {
  width: 100% !important;
}
.width-p-0 {
    width:0% !important;
  }
.min-width-p-0 {
    min-width:0% !important;
  }
.height-p-0 {
    width:0% !important;
  }
.min-height-p-0 {
    min-width:0% !important;
  }
.width-p-1 {
    width:1% !important;
  }
.min-width-p-1 {
    min-width:1% !important;
  }
.height-p-1 {
    width:1% !important;
  }
.min-height-p-1 {
    min-width:1% !important;
  }
.width-p-2 {
    width:2% !important;
  }
.min-width-p-2 {
    min-width:2% !important;
  }
.height-p-2 {
    width:2% !important;
  }
.min-height-p-2 {
    min-width:2% !important;
  }
.width-p-3 {
    width:3% !important;
  }
.min-width-p-3 {
    min-width:3% !important;
  }
.height-p-3 {
    width:3% !important;
  }
.min-height-p-3 {
    min-width:3% !important;
  }
.width-p-4 {
    width:4% !important;
  }
.min-width-p-4 {
    min-width:4% !important;
  }
.height-p-4 {
    width:4% !important;
  }
.min-height-p-4 {
    min-width:4% !important;
  }
.width-p-5 {
    width:5% !important;
  }
.min-width-p-5 {
    min-width:5% !important;
  }
.height-p-5 {
    width:5% !important;
  }
.min-height-p-5 {
    min-width:5% !important;
  }
.width-p-6 {
    width:6% !important;
  }
.min-width-p-6 {
    min-width:6% !important;
  }
.height-p-6 {
    width:6% !important;
  }
.min-height-p-6 {
    min-width:6% !important;
  }
.width-p-7 {
    width:7% !important;
  }
.min-width-p-7 {
    min-width:7% !important;
  }
.height-p-7 {
    width:7% !important;
  }
.min-height-p-7 {
    min-width:7% !important;
  }
.width-p-8 {
    width:8% !important;
  }
.min-width-p-8 {
    min-width:8% !important;
  }
.height-p-8 {
    width:8% !important;
  }
.min-height-p-8 {
    min-width:8% !important;
  }
.width-p-9 {
    width:9% !important;
  }
.min-width-p-9 {
    min-width:9% !important;
  }
.height-p-9 {
    width:9% !important;
  }
.min-height-p-9 {
    min-width:9% !important;
  }
.width-p-10 {
    width:10% !important;
  }
.min-width-p-10 {
    min-width:10% !important;
  }
.height-p-10 {
    width:10% !important;
  }
.min-height-p-10 {
    min-width:10% !important;
  }
.width-p-11 {
    width:11% !important;
  }
.min-width-p-11 {
    min-width:11% !important;
  }
.height-p-11 {
    width:11% !important;
  }
.min-height-p-11 {
    min-width:11% !important;
  }
.width-p-12 {
    width:12% !important;
  }
.min-width-p-12 {
    min-width:12% !important;
  }
.height-p-12 {
    width:12% !important;
  }
.min-height-p-12 {
    min-width:12% !important;
  }
.width-p-13 {
    width:13% !important;
  }
.min-width-p-13 {
    min-width:13% !important;
  }
.height-p-13 {
    width:13% !important;
  }
.min-height-p-13 {
    min-width:13% !important;
  }
.width-p-14 {
    width:14% !important;
  }
.min-width-p-14 {
    min-width:14% !important;
  }
.height-p-14 {
    width:14% !important;
  }
.min-height-p-14 {
    min-width:14% !important;
  }
.width-p-15 {
    width:15% !important;
  }
.min-width-p-15 {
    min-width:15% !important;
  }
.height-p-15 {
    width:15% !important;
  }
.min-height-p-15 {
    min-width:15% !important;
  }
.width-p-16 {
    width:16% !important;
  }
.min-width-p-16 {
    min-width:16% !important;
  }
.height-p-16 {
    width:16% !important;
  }
.min-height-p-16 {
    min-width:16% !important;
  }
.width-p-17 {
    width:17% !important;
  }
.min-width-p-17 {
    min-width:17% !important;
  }
.height-p-17 {
    width:17% !important;
  }
.min-height-p-17 {
    min-width:17% !important;
  }
.width-p-18 {
    width:18% !important;
  }
.min-width-p-18 {
    min-width:18% !important;
  }
.height-p-18 {
    width:18% !important;
  }
.min-height-p-18 {
    min-width:18% !important;
  }
.width-p-19 {
    width:19% !important;
  }
.min-width-p-19 {
    min-width:19% !important;
  }
.height-p-19 {
    width:19% !important;
  }
.min-height-p-19 {
    min-width:19% !important;
  }
.width-p-20 {
    width:20% !important;
  }
.min-width-p-20 {
    min-width:20% !important;
  }
.height-p-20 {
    width:20% !important;
  }
.min-height-p-20 {
    min-width:20% !important;
  }
.width-p-21 {
    width:21% !important;
  }
.min-width-p-21 {
    min-width:21% !important;
  }
.height-p-21 {
    width:21% !important;
  }
.min-height-p-21 {
    min-width:21% !important;
  }
.width-p-22 {
    width:22% !important;
  }
.min-width-p-22 {
    min-width:22% !important;
  }
.height-p-22 {
    width:22% !important;
  }
.min-height-p-22 {
    min-width:22% !important;
  }
.width-p-23 {
    width:23% !important;
  }
.min-width-p-23 {
    min-width:23% !important;
  }
.height-p-23 {
    width:23% !important;
  }
.min-height-p-23 {
    min-width:23% !important;
  }
.width-p-24 {
    width:24% !important;
  }
.min-width-p-24 {
    min-width:24% !important;
  }
.height-p-24 {
    width:24% !important;
  }
.min-height-p-24 {
    min-width:24% !important;
  }
.width-p-25 {
    width:25% !important;
  }
.min-width-p-25 {
    min-width:25% !important;
  }
.height-p-25 {
    width:25% !important;
  }
.min-height-p-25 {
    min-width:25% !important;
  }
.width-p-26 {
    width:26% !important;
  }
.min-width-p-26 {
    min-width:26% !important;
  }
.height-p-26 {
    width:26% !important;
  }
.min-height-p-26 {
    min-width:26% !important;
  }
.width-p-27 {
    width:27% !important;
  }
.min-width-p-27 {
    min-width:27% !important;
  }
.height-p-27 {
    width:27% !important;
  }
.min-height-p-27 {
    min-width:27% !important;
  }
.width-p-28 {
    width:28% !important;
  }
.min-width-p-28 {
    min-width:28% !important;
  }
.height-p-28 {
    width:28% !important;
  }
.min-height-p-28 {
    min-width:28% !important;
  }
.width-p-29 {
    width:29% !important;
  }
.min-width-p-29 {
    min-width:29% !important;
  }
.height-p-29 {
    width:29% !important;
  }
.min-height-p-29 {
    min-width:29% !important;
  }
.width-p-30 {
    width:30% !important;
  }
.min-width-p-30 {
    min-width:30% !important;
  }
.height-p-30 {
    width:30% !important;
  }
.min-height-p-30 {
    min-width:30% !important;
  }
.width-p-31 {
    width:31% !important;
  }
.min-width-p-31 {
    min-width:31% !important;
  }
.height-p-31 {
    width:31% !important;
  }
.min-height-p-31 {
    min-width:31% !important;
  }
.width-p-32 {
    width:32% !important;
  }
.min-width-p-32 {
    min-width:32% !important;
  }
.height-p-32 {
    width:32% !important;
  }
.min-height-p-32 {
    min-width:32% !important;
  }
.width-p-33 {
    width:33% !important;
  }
.min-width-p-33 {
    min-width:33% !important;
  }
.height-p-33 {
    width:33% !important;
  }
.min-height-p-33 {
    min-width:33% !important;
  }
.width-p-34 {
    width:34% !important;
  }
.min-width-p-34 {
    min-width:34% !important;
  }
.height-p-34 {
    width:34% !important;
  }
.min-height-p-34 {
    min-width:34% !important;
  }
.width-p-35 {
    width:35% !important;
  }
.min-width-p-35 {
    min-width:35% !important;
  }
.height-p-35 {
    width:35% !important;
  }
.min-height-p-35 {
    min-width:35% !important;
  }
.width-p-36 {
    width:36% !important;
  }
.min-width-p-36 {
    min-width:36% !important;
  }
.height-p-36 {
    width:36% !important;
  }
.min-height-p-36 {
    min-width:36% !important;
  }
.width-p-37 {
    width:37% !important;
  }
.min-width-p-37 {
    min-width:37% !important;
  }
.height-p-37 {
    width:37% !important;
  }
.min-height-p-37 {
    min-width:37% !important;
  }
.width-p-38 {
    width:38% !important;
  }
.min-width-p-38 {
    min-width:38% !important;
  }
.height-p-38 {
    width:38% !important;
  }
.min-height-p-38 {
    min-width:38% !important;
  }
.width-p-39 {
    width:39% !important;
  }
.min-width-p-39 {
    min-width:39% !important;
  }
.height-p-39 {
    width:39% !important;
  }
.min-height-p-39 {
    min-width:39% !important;
  }
.width-p-40 {
    width:40% !important;
  }
.min-width-p-40 {
    min-width:40% !important;
  }
.height-p-40 {
    width:40% !important;
  }
.min-height-p-40 {
    min-width:40% !important;
  }
.width-p-41 {
    width:41% !important;
  }
.min-width-p-41 {
    min-width:41% !important;
  }
.height-p-41 {
    width:41% !important;
  }
.min-height-p-41 {
    min-width:41% !important;
  }
.width-p-42 {
    width:42% !important;
  }
.min-width-p-42 {
    min-width:42% !important;
  }
.height-p-42 {
    width:42% !important;
  }
.min-height-p-42 {
    min-width:42% !important;
  }
.width-p-43 {
    width:43% !important;
  }
.min-width-p-43 {
    min-width:43% !important;
  }
.height-p-43 {
    width:43% !important;
  }
.min-height-p-43 {
    min-width:43% !important;
  }
.width-p-44 {
    width:44% !important;
  }
.min-width-p-44 {
    min-width:44% !important;
  }
.height-p-44 {
    width:44% !important;
  }
.min-height-p-44 {
    min-width:44% !important;
  }
.width-p-45 {
    width:45% !important;
  }
.min-width-p-45 {
    min-width:45% !important;
  }
.height-p-45 {
    width:45% !important;
  }
.min-height-p-45 {
    min-width:45% !important;
  }
.width-p-46 {
    width:46% !important;
  }
.min-width-p-46 {
    min-width:46% !important;
  }
.height-p-46 {
    width:46% !important;
  }
.min-height-p-46 {
    min-width:46% !important;
  }
.width-p-47 {
    width:47% !important;
  }
.min-width-p-47 {
    min-width:47% !important;
  }
.height-p-47 {
    width:47% !important;
  }
.min-height-p-47 {
    min-width:47% !important;
  }
.width-p-48 {
    width:48% !important;
  }
.min-width-p-48 {
    min-width:48% !important;
  }
.height-p-48 {
    width:48% !important;
  }
.min-height-p-48 {
    min-width:48% !important;
  }
.width-p-49 {
    width:49% !important;
  }
.min-width-p-49 {
    min-width:49% !important;
  }
.height-p-49 {
    width:49% !important;
  }
.min-height-p-49 {
    min-width:49% !important;
  }
.width-p-50 {
    width:50% !important;
  }
.min-width-p-50 {
    min-width:50% !important;
  }
.height-p-50 {
    width:50% !important;
  }
.min-height-p-50 {
    min-width:50% !important;
  }
.width-p-51 {
    width:51% !important;
  }
.min-width-p-51 {
    min-width:51% !important;
  }
.height-p-51 {
    width:51% !important;
  }
.min-height-p-51 {
    min-width:51% !important;
  }
.width-p-52 {
    width:52% !important;
  }
.min-width-p-52 {
    min-width:52% !important;
  }
.height-p-52 {
    width:52% !important;
  }
.min-height-p-52 {
    min-width:52% !important;
  }
.width-p-53 {
    width:53% !important;
  }
.min-width-p-53 {
    min-width:53% !important;
  }
.height-p-53 {
    width:53% !important;
  }
.min-height-p-53 {
    min-width:53% !important;
  }
.width-p-54 {
    width:54% !important;
  }
.min-width-p-54 {
    min-width:54% !important;
  }
.height-p-54 {
    width:54% !important;
  }
.min-height-p-54 {
    min-width:54% !important;
  }
.width-p-55 {
    width:55% !important;
  }
.min-width-p-55 {
    min-width:55% !important;
  }
.height-p-55 {
    width:55% !important;
  }
.min-height-p-55 {
    min-width:55% !important;
  }
.width-p-56 {
    width:56% !important;
  }
.min-width-p-56 {
    min-width:56% !important;
  }
.height-p-56 {
    width:56% !important;
  }
.min-height-p-56 {
    min-width:56% !important;
  }
.width-p-57 {
    width:57% !important;
  }
.min-width-p-57 {
    min-width:57% !important;
  }
.height-p-57 {
    width:57% !important;
  }
.min-height-p-57 {
    min-width:57% !important;
  }
.width-p-58 {
    width:58% !important;
  }
.min-width-p-58 {
    min-width:58% !important;
  }
.height-p-58 {
    width:58% !important;
  }
.min-height-p-58 {
    min-width:58% !important;
  }
.width-p-59 {
    width:59% !important;
  }
.min-width-p-59 {
    min-width:59% !important;
  }
.height-p-59 {
    width:59% !important;
  }
.min-height-p-59 {
    min-width:59% !important;
  }
.width-p-60 {
    width:60% !important;
  }
.min-width-p-60 {
    min-width:60% !important;
  }
.height-p-60 {
    width:60% !important;
  }
.min-height-p-60 {
    min-width:60% !important;
  }
.width-p-61 {
    width:61% !important;
  }
.min-width-p-61 {
    min-width:61% !important;
  }
.height-p-61 {
    width:61% !important;
  }
.min-height-p-61 {
    min-width:61% !important;
  }
.width-p-62 {
    width:62% !important;
  }
.min-width-p-62 {
    min-width:62% !important;
  }
.height-p-62 {
    width:62% !important;
  }
.min-height-p-62 {
    min-width:62% !important;
  }
.width-p-63 {
    width:63% !important;
  }
.min-width-p-63 {
    min-width:63% !important;
  }
.height-p-63 {
    width:63% !important;
  }
.min-height-p-63 {
    min-width:63% !important;
  }
.width-p-64 {
    width:64% !important;
  }
.min-width-p-64 {
    min-width:64% !important;
  }
.height-p-64 {
    width:64% !important;
  }
.min-height-p-64 {
    min-width:64% !important;
  }
.width-p-65 {
    width:65% !important;
  }
.min-width-p-65 {
    min-width:65% !important;
  }
.height-p-65 {
    width:65% !important;
  }
.min-height-p-65 {
    min-width:65% !important;
  }
.width-p-66 {
    width:66% !important;
  }
.min-width-p-66 {
    min-width:66% !important;
  }
.height-p-66 {
    width:66% !important;
  }
.min-height-p-66 {
    min-width:66% !important;
  }
.width-p-67 {
    width:67% !important;
  }
.min-width-p-67 {
    min-width:67% !important;
  }
.height-p-67 {
    width:67% !important;
  }
.min-height-p-67 {
    min-width:67% !important;
  }
.width-p-68 {
    width:68% !important;
  }
.min-width-p-68 {
    min-width:68% !important;
  }
.height-p-68 {
    width:68% !important;
  }
.min-height-p-68 {
    min-width:68% !important;
  }
.width-p-69 {
    width:69% !important;
  }
.min-width-p-69 {
    min-width:69% !important;
  }
.height-p-69 {
    width:69% !important;
  }
.min-height-p-69 {
    min-width:69% !important;
  }
.width-p-70 {
    width:70% !important;
  }
.min-width-p-70 {
    min-width:70% !important;
  }
.height-p-70 {
    width:70% !important;
  }
.min-height-p-70 {
    min-width:70% !important;
  }
.width-p-71 {
    width:71% !important;
  }
.min-width-p-71 {
    min-width:71% !important;
  }
.height-p-71 {
    width:71% !important;
  }
.min-height-p-71 {
    min-width:71% !important;
  }
.width-p-72 {
    width:72% !important;
  }
.min-width-p-72 {
    min-width:72% !important;
  }
.height-p-72 {
    width:72% !important;
  }
.min-height-p-72 {
    min-width:72% !important;
  }
.width-p-73 {
    width:73% !important;
  }
.min-width-p-73 {
    min-width:73% !important;
  }
.height-p-73 {
    width:73% !important;
  }
.min-height-p-73 {
    min-width:73% !important;
  }
.width-p-74 {
    width:74% !important;
  }
.min-width-p-74 {
    min-width:74% !important;
  }
.height-p-74 {
    width:74% !important;
  }
.min-height-p-74 {
    min-width:74% !important;
  }
.width-p-75 {
    width:75% !important;
  }
.min-width-p-75 {
    min-width:75% !important;
  }
.height-p-75 {
    width:75% !important;
  }
.min-height-p-75 {
    min-width:75% !important;
  }
.width-p-76 {
    width:76% !important;
  }
.min-width-p-76 {
    min-width:76% !important;
  }
.height-p-76 {
    width:76% !important;
  }
.min-height-p-76 {
    min-width:76% !important;
  }
.width-p-77 {
    width:77% !important;
  }
.min-width-p-77 {
    min-width:77% !important;
  }
.height-p-77 {
    width:77% !important;
  }
.min-height-p-77 {
    min-width:77% !important;
  }
.width-p-78 {
    width:78% !important;
  }
.min-width-p-78 {
    min-width:78% !important;
  }
.height-p-78 {
    width:78% !important;
  }
.min-height-p-78 {
    min-width:78% !important;
  }
.width-p-79 {
    width:79% !important;
  }
.min-width-p-79 {
    min-width:79% !important;
  }
.height-p-79 {
    width:79% !important;
  }
.min-height-p-79 {
    min-width:79% !important;
  }
.width-p-80 {
    width:80% !important;
  }
.min-width-p-80 {
    min-width:80% !important;
  }
.height-p-80 {
    width:80% !important;
  }
.min-height-p-80 {
    min-width:80% !important;
  }
.width-p-81 {
    width:81% !important;
  }
.min-width-p-81 {
    min-width:81% !important;
  }
.height-p-81 {
    width:81% !important;
  }
.min-height-p-81 {
    min-width:81% !important;
  }
.width-p-82 {
    width:82% !important;
  }
.min-width-p-82 {
    min-width:82% !important;
  }
.height-p-82 {
    width:82% !important;
  }
.min-height-p-82 {
    min-width:82% !important;
  }
.width-p-83 {
    width:83% !important;
  }
.min-width-p-83 {
    min-width:83% !important;
  }
.height-p-83 {
    width:83% !important;
  }
.min-height-p-83 {
    min-width:83% !important;
  }
.width-p-84 {
    width:84% !important;
  }
.min-width-p-84 {
    min-width:84% !important;
  }
.height-p-84 {
    width:84% !important;
  }
.min-height-p-84 {
    min-width:84% !important;
  }
.width-p-85 {
    width:85% !important;
  }
.min-width-p-85 {
    min-width:85% !important;
  }
.height-p-85 {
    width:85% !important;
  }
.min-height-p-85 {
    min-width:85% !important;
  }
.width-p-86 {
    width:86% !important;
  }
.min-width-p-86 {
    min-width:86% !important;
  }
.height-p-86 {
    width:86% !important;
  }
.min-height-p-86 {
    min-width:86% !important;
  }
.width-p-87 {
    width:87% !important;
  }
.min-width-p-87 {
    min-width:87% !important;
  }
.height-p-87 {
    width:87% !important;
  }
.min-height-p-87 {
    min-width:87% !important;
  }
.width-p-88 {
    width:88% !important;
  }
.min-width-p-88 {
    min-width:88% !important;
  }
.height-p-88 {
    width:88% !important;
  }
.min-height-p-88 {
    min-width:88% !important;
  }
.width-p-89 {
    width:89% !important;
  }
.min-width-p-89 {
    min-width:89% !important;
  }
.height-p-89 {
    width:89% !important;
  }
.min-height-p-89 {
    min-width:89% !important;
  }
.width-p-90 {
    width:90% !important;
  }
.min-width-p-90 {
    min-width:90% !important;
  }
.height-p-90 {
    width:90% !important;
  }
.min-height-p-90 {
    min-width:90% !important;
  }
.width-p-91 {
    width:91% !important;
  }
.min-width-p-91 {
    min-width:91% !important;
  }
.height-p-91 {
    width:91% !important;
  }
.min-height-p-91 {
    min-width:91% !important;
  }
.width-p-92 {
    width:92% !important;
  }
.min-width-p-92 {
    min-width:92% !important;
  }
.height-p-92 {
    width:92% !important;
  }
.min-height-p-92 {
    min-width:92% !important;
  }
.width-p-93 {
    width:93% !important;
  }
.min-width-p-93 {
    min-width:93% !important;
  }
.height-p-93 {
    width:93% !important;
  }
.min-height-p-93 {
    min-width:93% !important;
  }
.width-p-94 {
    width:94% !important;
  }
.min-width-p-94 {
    min-width:94% !important;
  }
.height-p-94 {
    width:94% !important;
  }
.min-height-p-94 {
    min-width:94% !important;
  }
.width-p-95 {
    width:95% !important;
  }
.min-width-p-95 {
    min-width:95% !important;
  }
.height-p-95 {
    width:95% !important;
  }
.min-height-p-95 {
    min-width:95% !important;
  }
.width-p-96 {
    width:96% !important;
  }
.min-width-p-96 {
    min-width:96% !important;
  }
.height-p-96 {
    width:96% !important;
  }
.min-height-p-96 {
    min-width:96% !important;
  }
.width-p-97 {
    width:97% !important;
  }
.min-width-p-97 {
    min-width:97% !important;
  }
.height-p-97 {
    width:97% !important;
  }
.min-height-p-97 {
    min-width:97% !important;
  }
.width-p-98 {
    width:98% !important;
  }
.min-width-p-98 {
    min-width:98% !important;
  }
.height-p-98 {
    width:98% !important;
  }
.min-height-p-98 {
    min-width:98% !important;
  }
.width-p-99 {
    width:99% !important;
  }
.min-width-p-99 {
    min-width:99% !important;
  }
.height-p-99 {
    width:99% !important;
  }
.min-height-p-99 {
    min-width:99% !important;
  }
.width-p-100 {
    width:100% !important;
  }
.min-width-p-100 {
    min-width:100% !important;
  }
.height-p-100 {
    width:100% !important;
  }
.min-height-p-100 {
    min-width:100% !important;
  }
.width-1 {
    width:10px !important;
  }
.max-width-1 {
    max-width:10px !important;
  }
.min-width-1 {
    min-width:10px !important;
  }
.width-5-1 {
    width:15px !important;
  }
.max-width-5-1 {
    max-width:15px !important;
  }
.min-width-5-1 {
    min-width:15px !important;
  }
.height-1 {
    height:10px !important;
  }
.max-height-1 {
    max-height:10px !important;
  }
.min-height-1 {
    min-height:10px !important;
  }
.width-2 {
    width:20px !important;
  }
.max-width-2 {
    max-width:20px !important;
  }
.min-width-2 {
    min-width:20px !important;
  }
.width-5-2 {
    width:25px !important;
  }
.max-width-5-2 {
    max-width:25px !important;
  }
.min-width-5-2 {
    min-width:25px !important;
  }
.height-2 {
    height:20px !important;
  }
.max-height-2 {
    max-height:20px !important;
  }
.min-height-2 {
    min-height:20px !important;
  }
.width-3 {
    width:30px !important;
  }
.max-width-3 {
    max-width:30px !important;
  }
.min-width-3 {
    min-width:30px !important;
  }
.width-5-3 {
    width:35px !important;
  }
.max-width-5-3 {
    max-width:35px !important;
  }
.min-width-5-3 {
    min-width:35px !important;
  }
.height-3 {
    height:30px !important;
  }
.max-height-3 {
    max-height:30px !important;
  }
.min-height-3 {
    min-height:30px !important;
  }
.width-4 {
    width:40px !important;
  }
.max-width-4 {
    max-width:40px !important;
  }
.min-width-4 {
    min-width:40px !important;
  }
.width-5-4 {
    width:45px !important;
  }
.max-width-5-4 {
    max-width:45px !important;
  }
.min-width-5-4 {
    min-width:45px !important;
  }
.height-4 {
    height:40px !important;
  }
.max-height-4 {
    max-height:40px !important;
  }
.min-height-4 {
    min-height:40px !important;
  }
.width-5 {
    width:50px !important;
  }
.max-width-5 {
    max-width:50px !important;
  }
.min-width-5 {
    min-width:50px !important;
  }
.width-5-5 {
    width:55px !important;
  }
.max-width-5-5 {
    max-width:55px !important;
  }
.min-width-5-5 {
    min-width:55px !important;
  }
.height-5 {
    height:50px !important;
  }
.max-height-5 {
    max-height:50px !important;
  }
.min-height-5 {
    min-height:50px !important;
  }
.width-6 {
    width:60px !important;
  }
.max-width-6 {
    max-width:60px !important;
  }
.min-width-6 {
    min-width:60px !important;
  }
.width-5-6 {
    width:65px !important;
  }
.max-width-5-6 {
    max-width:65px !important;
  }
.min-width-5-6 {
    min-width:65px !important;
  }
.height-6 {
    height:60px !important;
  }
.max-height-6 {
    max-height:60px !important;
  }
.min-height-6 {
    min-height:60px !important;
  }
.width-7 {
    width:70px !important;
  }
.max-width-7 {
    max-width:70px !important;
  }
.min-width-7 {
    min-width:70px !important;
  }
.width-5-7 {
    width:75px !important;
  }
.max-width-5-7 {
    max-width:75px !important;
  }
.min-width-5-7 {
    min-width:75px !important;
  }
.height-7 {
    height:70px !important;
  }
.max-height-7 {
    max-height:70px !important;
  }
.min-height-7 {
    min-height:70px !important;
  }
.width-8 {
    width:80px !important;
  }
.max-width-8 {
    max-width:80px !important;
  }
.min-width-8 {
    min-width:80px !important;
  }
.width-5-8 {
    width:85px !important;
  }
.max-width-5-8 {
    max-width:85px !important;
  }
.min-width-5-8 {
    min-width:85px !important;
  }
.height-8 {
    height:80px !important;
  }
.max-height-8 {
    max-height:80px !important;
  }
.min-height-8 {
    min-height:80px !important;
  }
.width-9 {
    width:90px !important;
  }
.max-width-9 {
    max-width:90px !important;
  }
.min-width-9 {
    min-width:90px !important;
  }
.width-5-9 {
    width:95px !important;
  }
.max-width-5-9 {
    max-width:95px !important;
  }
.min-width-5-9 {
    min-width:95px !important;
  }
.height-9 {
    height:90px !important;
  }
.max-height-9 {
    max-height:90px !important;
  }
.min-height-9 {
    min-height:90px !important;
  }
.width-10 {
    width:100px !important;
  }
.max-width-10 {
    max-width:100px !important;
  }
.min-width-10 {
    min-width:100px !important;
  }
.width-5-10 {
    width:105px !important;
  }
.max-width-5-10 {
    max-width:105px !important;
  }
.min-width-5-10 {
    min-width:105px !important;
  }
.height-10 {
    height:100px !important;
  }
.max-height-10 {
    max-height:100px !important;
  }
.min-height-10 {
    min-height:100px !important;
  }
.width-11 {
    width:110px !important;
  }
.max-width-11 {
    max-width:110px !important;
  }
.min-width-11 {
    min-width:110px !important;
  }
.width-5-11 {
    width:115px !important;
  }
.max-width-5-11 {
    max-width:115px !important;
  }
.min-width-5-11 {
    min-width:115px !important;
  }
.height-11 {
    height:110px !important;
  }
.max-height-11 {
    max-height:110px !important;
  }
.min-height-11 {
    min-height:110px !important;
  }
.width-12 {
    width:120px !important;
  }
.max-width-12 {
    max-width:120px !important;
  }
.min-width-12 {
    min-width:120px !important;
  }
.width-5-12 {
    width:125px !important;
  }
.max-width-5-12 {
    max-width:125px !important;
  }
.min-width-5-12 {
    min-width:125px !important;
  }
.height-12 {
    height:120px !important;
  }
.max-height-12 {
    max-height:120px !important;
  }
.min-height-12 {
    min-height:120px !important;
  }
.width-13 {
    width:130px !important;
  }
.max-width-13 {
    max-width:130px !important;
  }
.min-width-13 {
    min-width:130px !important;
  }
.width-5-13 {
    width:135px !important;
  }
.max-width-5-13 {
    max-width:135px !important;
  }
.min-width-5-13 {
    min-width:135px !important;
  }
.height-13 {
    height:130px !important;
  }
.max-height-13 {
    max-height:130px !important;
  }
.min-height-13 {
    min-height:130px !important;
  }
.width-14 {
    width:140px !important;
  }
.max-width-14 {
    max-width:140px !important;
  }
.min-width-14 {
    min-width:140px !important;
  }
.width-5-14 {
    width:145px !important;
  }
.max-width-5-14 {
    max-width:145px !important;
  }
.min-width-5-14 {
    min-width:145px !important;
  }
.height-14 {
    height:140px !important;
  }
.max-height-14 {
    max-height:140px !important;
  }
.min-height-14 {
    min-height:140px !important;
  }
.width-15 {
    width:150px !important;
  }
.max-width-15 {
    max-width:150px !important;
  }
.min-width-15 {
    min-width:150px !important;
  }
.width-5-15 {
    width:155px !important;
  }
.max-width-5-15 {
    max-width:155px !important;
  }
.min-width-5-15 {
    min-width:155px !important;
  }
.height-15 {
    height:150px !important;
  }
.max-height-15 {
    max-height:150px !important;
  }
.min-height-15 {
    min-height:150px !important;
  }
.width-16 {
    width:160px !important;
  }
.max-width-16 {
    max-width:160px !important;
  }
.min-width-16 {
    min-width:160px !important;
  }
.width-5-16 {
    width:165px !important;
  }
.max-width-5-16 {
    max-width:165px !important;
  }
.min-width-5-16 {
    min-width:165px !important;
  }
.height-16 {
    height:160px !important;
  }
.max-height-16 {
    max-height:160px !important;
  }
.min-height-16 {
    min-height:160px !important;
  }
.width-17 {
    width:170px !important;
  }
.max-width-17 {
    max-width:170px !important;
  }
.min-width-17 {
    min-width:170px !important;
  }
.width-5-17 {
    width:175px !important;
  }
.max-width-5-17 {
    max-width:175px !important;
  }
.min-width-5-17 {
    min-width:175px !important;
  }
.height-17 {
    height:170px !important;
  }
.max-height-17 {
    max-height:170px !important;
  }
.min-height-17 {
    min-height:170px !important;
  }
.width-18 {
    width:180px !important;
  }
.max-width-18 {
    max-width:180px !important;
  }
.min-width-18 {
    min-width:180px !important;
  }
.width-5-18 {
    width:185px !important;
  }
.max-width-5-18 {
    max-width:185px !important;
  }
.min-width-5-18 {
    min-width:185px !important;
  }
.height-18 {
    height:180px !important;
  }
.max-height-18 {
    max-height:180px !important;
  }
.min-height-18 {
    min-height:180px !important;
  }
.width-19 {
    width:190px !important;
  }
.max-width-19 {
    max-width:190px !important;
  }
.min-width-19 {
    min-width:190px !important;
  }
.width-5-19 {
    width:195px !important;
  }
.max-width-5-19 {
    max-width:195px !important;
  }
.min-width-5-19 {
    min-width:195px !important;
  }
.height-19 {
    height:190px !important;
  }
.max-height-19 {
    max-height:190px !important;
  }
.min-height-19 {
    min-height:190px !important;
  }
.width-20 {
    width:200px !important;
  }
.max-width-20 {
    max-width:200px !important;
  }
.min-width-20 {
    min-width:200px !important;
  }
.width-5-20 {
    width:205px !important;
  }
.max-width-5-20 {
    max-width:205px !important;
  }
.min-width-5-20 {
    min-width:205px !important;
  }
.height-20 {
    height:200px !important;
  }
.max-height-20 {
    max-height:200px !important;
  }
.min-height-20 {
    min-height:200px !important;
  }
.width-21 {
    width:210px !important;
  }
.max-width-21 {
    max-width:210px !important;
  }
.min-width-21 {
    min-width:210px !important;
  }
.width-5-21 {
    width:215px !important;
  }
.max-width-5-21 {
    max-width:215px !important;
  }
.min-width-5-21 {
    min-width:215px !important;
  }
.height-21 {
    height:210px !important;
  }
.max-height-21 {
    max-height:210px !important;
  }
.min-height-21 {
    min-height:210px !important;
  }
.width-22 {
    width:220px !important;
  }
.max-width-22 {
    max-width:220px !important;
  }
.min-width-22 {
    min-width:220px !important;
  }
.width-5-22 {
    width:225px !important;
  }
.max-width-5-22 {
    max-width:225px !important;
  }
.min-width-5-22 {
    min-width:225px !important;
  }
.height-22 {
    height:220px !important;
  }
.max-height-22 {
    max-height:220px !important;
  }
.min-height-22 {
    min-height:220px !important;
  }
.width-23 {
    width:230px !important;
  }
.max-width-23 {
    max-width:230px !important;
  }
.min-width-23 {
    min-width:230px !important;
  }
.width-5-23 {
    width:235px !important;
  }
.max-width-5-23 {
    max-width:235px !important;
  }
.min-width-5-23 {
    min-width:235px !important;
  }
.height-23 {
    height:230px !important;
  }
.max-height-23 {
    max-height:230px !important;
  }
.min-height-23 {
    min-height:230px !important;
  }
.width-24 {
    width:240px !important;
  }
.max-width-24 {
    max-width:240px !important;
  }
.min-width-24 {
    min-width:240px !important;
  }
.width-5-24 {
    width:245px !important;
  }
.max-width-5-24 {
    max-width:245px !important;
  }
.min-width-5-24 {
    min-width:245px !important;
  }
.height-24 {
    height:240px !important;
  }
.max-height-24 {
    max-height:240px !important;
  }
.min-height-24 {
    min-height:240px !important;
  }
.width-25 {
    width:250px !important;
  }
.max-width-25 {
    max-width:250px !important;
  }
.min-width-25 {
    min-width:250px !important;
  }
.width-5-25 {
    width:255px !important;
  }
.max-width-5-25 {
    max-width:255px !important;
  }
.min-width-5-25 {
    min-width:255px !important;
  }
.height-25 {
    height:250px !important;
  }
.max-height-25 {
    max-height:250px !important;
  }
.min-height-25 {
    min-height:250px !important;
  }
.width-26 {
    width:260px !important;
  }
.max-width-26 {
    max-width:260px !important;
  }
.min-width-26 {
    min-width:260px !important;
  }
.width-5-26 {
    width:265px !important;
  }
.max-width-5-26 {
    max-width:265px !important;
  }
.min-width-5-26 {
    min-width:265px !important;
  }
.height-26 {
    height:260px !important;
  }
.max-height-26 {
    max-height:260px !important;
  }
.min-height-26 {
    min-height:260px !important;
  }
.width-27 {
    width:270px !important;
  }
.max-width-27 {
    max-width:270px !important;
  }
.min-width-27 {
    min-width:270px !important;
  }
.width-5-27 {
    width:275px !important;
  }
.max-width-5-27 {
    max-width:275px !important;
  }
.min-width-5-27 {
    min-width:275px !important;
  }
.height-27 {
    height:270px !important;
  }
.max-height-27 {
    max-height:270px !important;
  }
.min-height-27 {
    min-height:270px !important;
  }
.width-28 {
    width:280px !important;
  }
.max-width-28 {
    max-width:280px !important;
  }
.min-width-28 {
    min-width:280px !important;
  }
.width-5-28 {
    width:285px !important;
  }
.max-width-5-28 {
    max-width:285px !important;
  }
.min-width-5-28 {
    min-width:285px !important;
  }
.height-28 {
    height:280px !important;
  }
.max-height-28 {
    max-height:280px !important;
  }
.min-height-28 {
    min-height:280px !important;
  }
.width-29 {
    width:290px !important;
  }
.max-width-29 {
    max-width:290px !important;
  }
.min-width-29 {
    min-width:290px !important;
  }
.width-5-29 {
    width:295px !important;
  }
.max-width-5-29 {
    max-width:295px !important;
  }
.min-width-5-29 {
    min-width:295px !important;
  }
.height-29 {
    height:290px !important;
  }
.max-height-29 {
    max-height:290px !important;
  }
.min-height-29 {
    min-height:290px !important;
  }
.width-30 {
    width:300px !important;
  }
.max-width-30 {
    max-width:300px !important;
  }
.min-width-30 {
    min-width:300px !important;
  }
.width-5-30 {
    width:305px !important;
  }
.max-width-5-30 {
    max-width:305px !important;
  }
.min-width-5-30 {
    min-width:305px !important;
  }
.height-30 {
    height:300px !important;
  }
.max-height-30 {
    max-height:300px !important;
  }
.min-height-30 {
    min-height:300px !important;
  }
.width-31 {
    width:310px !important;
  }
.max-width-31 {
    max-width:310px !important;
  }
.min-width-31 {
    min-width:310px !important;
  }
.width-5-31 {
    width:315px !important;
  }
.max-width-5-31 {
    max-width:315px !important;
  }
.min-width-5-31 {
    min-width:315px !important;
  }
.height-31 {
    height:310px !important;
  }
.max-height-31 {
    max-height:310px !important;
  }
.min-height-31 {
    min-height:310px !important;
  }
.width-32 {
    width:320px !important;
  }
.max-width-32 {
    max-width:320px !important;
  }
.min-width-32 {
    min-width:320px !important;
  }
.width-5-32 {
    width:325px !important;
  }
.max-width-5-32 {
    max-width:325px !important;
  }
.min-width-5-32 {
    min-width:325px !important;
  }
.height-32 {
    height:320px !important;
  }
.max-height-32 {
    max-height:320px !important;
  }
.min-height-32 {
    min-height:320px !important;
  }
.width-33 {
    width:330px !important;
  }
.max-width-33 {
    max-width:330px !important;
  }
.min-width-33 {
    min-width:330px !important;
  }
.width-5-33 {
    width:335px !important;
  }
.max-width-5-33 {
    max-width:335px !important;
  }
.min-width-5-33 {
    min-width:335px !important;
  }
.height-33 {
    height:330px !important;
  }
.max-height-33 {
    max-height:330px !important;
  }
.min-height-33 {
    min-height:330px !important;
  }
.width-34 {
    width:340px !important;
  }
.max-width-34 {
    max-width:340px !important;
  }
.min-width-34 {
    min-width:340px !important;
  }
.width-5-34 {
    width:345px !important;
  }
.max-width-5-34 {
    max-width:345px !important;
  }
.min-width-5-34 {
    min-width:345px !important;
  }
.height-34 {
    height:340px !important;
  }
.max-height-34 {
    max-height:340px !important;
  }
.min-height-34 {
    min-height:340px !important;
  }
.width-35 {
    width:350px !important;
  }
.max-width-35 {
    max-width:350px !important;
  }
.min-width-35 {
    min-width:350px !important;
  }
.width-5-35 {
    width:355px !important;
  }
.max-width-5-35 {
    max-width:355px !important;
  }
.min-width-5-35 {
    min-width:355px !important;
  }
.height-35 {
    height:350px !important;
  }
.max-height-35 {
    max-height:350px !important;
  }
.min-height-35 {
    min-height:350px !important;
  }
.width-36 {
    width:360px !important;
  }
.max-width-36 {
    max-width:360px !important;
  }
.min-width-36 {
    min-width:360px !important;
  }
.width-5-36 {
    width:365px !important;
  }
.max-width-5-36 {
    max-width:365px !important;
  }
.min-width-5-36 {
    min-width:365px !important;
  }
.height-36 {
    height:360px !important;
  }
.max-height-36 {
    max-height:360px !important;
  }
.min-height-36 {
    min-height:360px !important;
  }
.width-37 {
    width:370px !important;
  }
.max-width-37 {
    max-width:370px !important;
  }
.min-width-37 {
    min-width:370px !important;
  }
.width-5-37 {
    width:375px !important;
  }
.max-width-5-37 {
    max-width:375px !important;
  }
.min-width-5-37 {
    min-width:375px !important;
  }
.height-37 {
    height:370px !important;
  }
.max-height-37 {
    max-height:370px !important;
  }
.min-height-37 {
    min-height:370px !important;
  }
.width-38 {
    width:380px !important;
  }
.max-width-38 {
    max-width:380px !important;
  }
.min-width-38 {
    min-width:380px !important;
  }
.width-5-38 {
    width:385px !important;
  }
.max-width-5-38 {
    max-width:385px !important;
  }
.min-width-5-38 {
    min-width:385px !important;
  }
.height-38 {
    height:380px !important;
  }
.max-height-38 {
    max-height:380px !important;
  }
.min-height-38 {
    min-height:380px !important;
  }
.width-39 {
    width:390px !important;
  }
.max-width-39 {
    max-width:390px !important;
  }
.min-width-39 {
    min-width:390px !important;
  }
.width-5-39 {
    width:395px !important;
  }
.max-width-5-39 {
    max-width:395px !important;
  }
.min-width-5-39 {
    min-width:395px !important;
  }
.height-39 {
    height:390px !important;
  }
.max-height-39 {
    max-height:390px !important;
  }
.min-height-39 {
    min-height:390px !important;
  }
.width-40 {
    width:400px !important;
  }
.max-width-40 {
    max-width:400px !important;
  }
.min-width-40 {
    min-width:400px !important;
  }
.width-5-40 {
    width:405px !important;
  }
.max-width-5-40 {
    max-width:405px !important;
  }
.min-width-5-40 {
    min-width:405px !important;
  }
.height-40 {
    height:400px !important;
  }
.max-height-40 {
    max-height:400px !important;
  }
.min-height-40 {
    min-height:400px !important;
  }
.width-41 {
    width:410px !important;
  }
.max-width-41 {
    max-width:410px !important;
  }
.min-width-41 {
    min-width:410px !important;
  }
.width-5-41 {
    width:415px !important;
  }
.max-width-5-41 {
    max-width:415px !important;
  }
.min-width-5-41 {
    min-width:415px !important;
  }
.height-41 {
    height:410px !important;
  }
.max-height-41 {
    max-height:410px !important;
  }
.min-height-41 {
    min-height:410px !important;
  }
.width-42 {
    width:420px !important;
  }
.max-width-42 {
    max-width:420px !important;
  }
.min-width-42 {
    min-width:420px !important;
  }
.width-5-42 {
    width:425px !important;
  }
.max-width-5-42 {
    max-width:425px !important;
  }
.min-width-5-42 {
    min-width:425px !important;
  }
.height-42 {
    height:420px !important;
  }
.max-height-42 {
    max-height:420px !important;
  }
.min-height-42 {
    min-height:420px !important;
  }
.width-43 {
    width:430px !important;
  }
.max-width-43 {
    max-width:430px !important;
  }
.min-width-43 {
    min-width:430px !important;
  }
.width-5-43 {
    width:435px !important;
  }
.max-width-5-43 {
    max-width:435px !important;
  }
.min-width-5-43 {
    min-width:435px !important;
  }
.height-43 {
    height:430px !important;
  }
.max-height-43 {
    max-height:430px !important;
  }
.min-height-43 {
    min-height:430px !important;
  }
.width-44 {
    width:440px !important;
  }
.max-width-44 {
    max-width:440px !important;
  }
.min-width-44 {
    min-width:440px !important;
  }
.width-5-44 {
    width:445px !important;
  }
.max-width-5-44 {
    max-width:445px !important;
  }
.min-width-5-44 {
    min-width:445px !important;
  }
.height-44 {
    height:440px !important;
  }
.max-height-44 {
    max-height:440px !important;
  }
.min-height-44 {
    min-height:440px !important;
  }
.width-45 {
    width:450px !important;
  }
.max-width-45 {
    max-width:450px !important;
  }
.min-width-45 {
    min-width:450px !important;
  }
.width-5-45 {
    width:455px !important;
  }
.max-width-5-45 {
    max-width:455px !important;
  }
.min-width-5-45 {
    min-width:455px !important;
  }
.height-45 {
    height:450px !important;
  }
.max-height-45 {
    max-height:450px !important;
  }
.min-height-45 {
    min-height:450px !important;
  }
.width-46 {
    width:460px !important;
  }
.max-width-46 {
    max-width:460px !important;
  }
.min-width-46 {
    min-width:460px !important;
  }
.width-5-46 {
    width:465px !important;
  }
.max-width-5-46 {
    max-width:465px !important;
  }
.min-width-5-46 {
    min-width:465px !important;
  }
.height-46 {
    height:460px !important;
  }
.max-height-46 {
    max-height:460px !important;
  }
.min-height-46 {
    min-height:460px !important;
  }
.width-47 {
    width:470px !important;
  }
.max-width-47 {
    max-width:470px !important;
  }
.min-width-47 {
    min-width:470px !important;
  }
.width-5-47 {
    width:475px !important;
  }
.max-width-5-47 {
    max-width:475px !important;
  }
.min-width-5-47 {
    min-width:475px !important;
  }
.height-47 {
    height:470px !important;
  }
.max-height-47 {
    max-height:470px !important;
  }
.min-height-47 {
    min-height:470px !important;
  }
.width-48 {
    width:480px !important;
  }
.max-width-48 {
    max-width:480px !important;
  }
.min-width-48 {
    min-width:480px !important;
  }
.width-5-48 {
    width:485px !important;
  }
.max-width-5-48 {
    max-width:485px !important;
  }
.min-width-5-48 {
    min-width:485px !important;
  }
.height-48 {
    height:480px !important;
  }
.max-height-48 {
    max-height:480px !important;
  }
.min-height-48 {
    min-height:480px !important;
  }
.width-49 {
    width:490px !important;
  }
.max-width-49 {
    max-width:490px !important;
  }
.min-width-49 {
    min-width:490px !important;
  }
.width-5-49 {
    width:495px !important;
  }
.max-width-5-49 {
    max-width:495px !important;
  }
.min-width-5-49 {
    min-width:495px !important;
  }
.height-49 {
    height:490px !important;
  }
.max-height-49 {
    max-height:490px !important;
  }
.min-height-49 {
    min-height:490px !important;
  }
.width-50 {
    width:500px !important;
  }
.max-width-50 {
    max-width:500px !important;
  }
.min-width-50 {
    min-width:500px !important;
  }
.width-5-50 {
    width:505px !important;
  }
.max-width-5-50 {
    max-width:505px !important;
  }
.min-width-5-50 {
    min-width:505px !important;
  }
.height-50 {
    height:500px !important;
  }
.max-height-50 {
    max-height:500px !important;
  }
.min-height-50 {
    min-height:500px !important;
  }
.width-51 {
    width:510px !important;
  }
.max-width-51 {
    max-width:510px !important;
  }
.min-width-51 {
    min-width:510px !important;
  }
.width-5-51 {
    width:515px !important;
  }
.max-width-5-51 {
    max-width:515px !important;
  }
.min-width-5-51 {
    min-width:515px !important;
  }
.height-51 {
    height:510px !important;
  }
.max-height-51 {
    max-height:510px !important;
  }
.min-height-51 {
    min-height:510px !important;
  }
.width-52 {
    width:520px !important;
  }
.max-width-52 {
    max-width:520px !important;
  }
.min-width-52 {
    min-width:520px !important;
  }
.width-5-52 {
    width:525px !important;
  }
.max-width-5-52 {
    max-width:525px !important;
  }
.min-width-5-52 {
    min-width:525px !important;
  }
.height-52 {
    height:520px !important;
  }
.max-height-52 {
    max-height:520px !important;
  }
.min-height-52 {
    min-height:520px !important;
  }
.width-53 {
    width:530px !important;
  }
.max-width-53 {
    max-width:530px !important;
  }
.min-width-53 {
    min-width:530px !important;
  }
.width-5-53 {
    width:535px !important;
  }
.max-width-5-53 {
    max-width:535px !important;
  }
.min-width-5-53 {
    min-width:535px !important;
  }
.height-53 {
    height:530px !important;
  }
.max-height-53 {
    max-height:530px !important;
  }
.min-height-53 {
    min-height:530px !important;
  }
.width-54 {
    width:540px !important;
  }
.max-width-54 {
    max-width:540px !important;
  }
.min-width-54 {
    min-width:540px !important;
  }
.width-5-54 {
    width:545px !important;
  }
.max-width-5-54 {
    max-width:545px !important;
  }
.min-width-5-54 {
    min-width:545px !important;
  }
.height-54 {
    height:540px !important;
  }
.max-height-54 {
    max-height:540px !important;
  }
.min-height-54 {
    min-height:540px !important;
  }
.width-55 {
    width:550px !important;
  }
.max-width-55 {
    max-width:550px !important;
  }
.min-width-55 {
    min-width:550px !important;
  }
.width-5-55 {
    width:555px !important;
  }
.max-width-5-55 {
    max-width:555px !important;
  }
.min-width-5-55 {
    min-width:555px !important;
  }
.height-55 {
    height:550px !important;
  }
.max-height-55 {
    max-height:550px !important;
  }
.min-height-55 {
    min-height:550px !important;
  }
.width-56 {
    width:560px !important;
  }
.max-width-56 {
    max-width:560px !important;
  }
.min-width-56 {
    min-width:560px !important;
  }
.width-5-56 {
    width:565px !important;
  }
.max-width-5-56 {
    max-width:565px !important;
  }
.min-width-5-56 {
    min-width:565px !important;
  }
.height-56 {
    height:560px !important;
  }
.max-height-56 {
    max-height:560px !important;
  }
.min-height-56 {
    min-height:560px !important;
  }
.width-57 {
    width:570px !important;
  }
.max-width-57 {
    max-width:570px !important;
  }
.min-width-57 {
    min-width:570px !important;
  }
.width-5-57 {
    width:575px !important;
  }
.max-width-5-57 {
    max-width:575px !important;
  }
.min-width-5-57 {
    min-width:575px !important;
  }
.height-57 {
    height:570px !important;
  }
.max-height-57 {
    max-height:570px !important;
  }
.min-height-57 {
    min-height:570px !important;
  }
.width-58 {
    width:580px !important;
  }
.max-width-58 {
    max-width:580px !important;
  }
.min-width-58 {
    min-width:580px !important;
  }
.width-5-58 {
    width:585px !important;
  }
.max-width-5-58 {
    max-width:585px !important;
  }
.min-width-5-58 {
    min-width:585px !important;
  }
.height-58 {
    height:580px !important;
  }
.max-height-58 {
    max-height:580px !important;
  }
.min-height-58 {
    min-height:580px !important;
  }
.width-59 {
    width:590px !important;
  }
.max-width-59 {
    max-width:590px !important;
  }
.min-width-59 {
    min-width:590px !important;
  }
.width-5-59 {
    width:595px !important;
  }
.max-width-5-59 {
    max-width:595px !important;
  }
.min-width-5-59 {
    min-width:595px !important;
  }
.height-59 {
    height:590px !important;
  }
.max-height-59 {
    max-height:590px !important;
  }
.min-height-59 {
    min-height:590px !important;
  }
.width-60 {
    width:600px !important;
  }
.max-width-60 {
    max-width:600px !important;
  }
.min-width-60 {
    min-width:600px !important;
  }
.width-5-60 {
    width:605px !important;
  }
.max-width-5-60 {
    max-width:605px !important;
  }
.min-width-5-60 {
    min-width:605px !important;
  }
.height-60 {
    height:600px !important;
  }
.max-height-60 {
    max-height:600px !important;
  }
.min-height-60 {
    min-height:600px !important;
  }
.width-61 {
    width:610px !important;
  }
.max-width-61 {
    max-width:610px !important;
  }
.min-width-61 {
    min-width:610px !important;
  }
.width-5-61 {
    width:615px !important;
  }
.max-width-5-61 {
    max-width:615px !important;
  }
.min-width-5-61 {
    min-width:615px !important;
  }
.height-61 {
    height:610px !important;
  }
.max-height-61 {
    max-height:610px !important;
  }
.min-height-61 {
    min-height:610px !important;
  }
.width-62 {
    width:620px !important;
  }
.max-width-62 {
    max-width:620px !important;
  }
.min-width-62 {
    min-width:620px !important;
  }
.width-5-62 {
    width:625px !important;
  }
.max-width-5-62 {
    max-width:625px !important;
  }
.min-width-5-62 {
    min-width:625px !important;
  }
.height-62 {
    height:620px !important;
  }
.max-height-62 {
    max-height:620px !important;
  }
.min-height-62 {
    min-height:620px !important;
  }
.width-63 {
    width:630px !important;
  }
.max-width-63 {
    max-width:630px !important;
  }
.min-width-63 {
    min-width:630px !important;
  }
.width-5-63 {
    width:635px !important;
  }
.max-width-5-63 {
    max-width:635px !important;
  }
.min-width-5-63 {
    min-width:635px !important;
  }
.height-63 {
    height:630px !important;
  }
.max-height-63 {
    max-height:630px !important;
  }
.min-height-63 {
    min-height:630px !important;
  }
.width-64 {
    width:640px !important;
  }
.max-width-64 {
    max-width:640px !important;
  }
.min-width-64 {
    min-width:640px !important;
  }
.width-5-64 {
    width:645px !important;
  }
.max-width-5-64 {
    max-width:645px !important;
  }
.min-width-5-64 {
    min-width:645px !important;
  }
.height-64 {
    height:640px !important;
  }
.max-height-64 {
    max-height:640px !important;
  }
.min-height-64 {
    min-height:640px !important;
  }
.width-65 {
    width:650px !important;
  }
.max-width-65 {
    max-width:650px !important;
  }
.min-width-65 {
    min-width:650px !important;
  }
.width-5-65 {
    width:655px !important;
  }
.max-width-5-65 {
    max-width:655px !important;
  }
.min-width-5-65 {
    min-width:655px !important;
  }
.height-65 {
    height:650px !important;
  }
.max-height-65 {
    max-height:650px !important;
  }
.min-height-65 {
    min-height:650px !important;
  }
.width-66 {
    width:660px !important;
  }
.max-width-66 {
    max-width:660px !important;
  }
.min-width-66 {
    min-width:660px !important;
  }
.width-5-66 {
    width:665px !important;
  }
.max-width-5-66 {
    max-width:665px !important;
  }
.min-width-5-66 {
    min-width:665px !important;
  }
.height-66 {
    height:660px !important;
  }
.max-height-66 {
    max-height:660px !important;
  }
.min-height-66 {
    min-height:660px !important;
  }
.width-67 {
    width:670px !important;
  }
.max-width-67 {
    max-width:670px !important;
  }
.min-width-67 {
    min-width:670px !important;
  }
.width-5-67 {
    width:675px !important;
  }
.max-width-5-67 {
    max-width:675px !important;
  }
.min-width-5-67 {
    min-width:675px !important;
  }
.height-67 {
    height:670px !important;
  }
.max-height-67 {
    max-height:670px !important;
  }
.min-height-67 {
    min-height:670px !important;
  }
.width-68 {
    width:680px !important;
  }
.max-width-68 {
    max-width:680px !important;
  }
.min-width-68 {
    min-width:680px !important;
  }
.width-5-68 {
    width:685px !important;
  }
.max-width-5-68 {
    max-width:685px !important;
  }
.min-width-5-68 {
    min-width:685px !important;
  }
.height-68 {
    height:680px !important;
  }
.max-height-68 {
    max-height:680px !important;
  }
.min-height-68 {
    min-height:680px !important;
  }
.width-69 {
    width:690px !important;
  }
.max-width-69 {
    max-width:690px !important;
  }
.min-width-69 {
    min-width:690px !important;
  }
.width-5-69 {
    width:695px !important;
  }
.max-width-5-69 {
    max-width:695px !important;
  }
.min-width-5-69 {
    min-width:695px !important;
  }
.height-69 {
    height:690px !important;
  }
.max-height-69 {
    max-height:690px !important;
  }
.min-height-69 {
    min-height:690px !important;
  }
.width-70 {
    width:700px !important;
  }
.max-width-70 {
    max-width:700px !important;
  }
.min-width-70 {
    min-width:700px !important;
  }
.width-5-70 {
    width:705px !important;
  }
.max-width-5-70 {
    max-width:705px !important;
  }
.min-width-5-70 {
    min-width:705px !important;
  }
.height-70 {
    height:700px !important;
  }
.max-height-70 {
    max-height:700px !important;
  }
.min-height-70 {
    min-height:700px !important;
  }
.width-71 {
    width:710px !important;
  }
.max-width-71 {
    max-width:710px !important;
  }
.min-width-71 {
    min-width:710px !important;
  }
.width-5-71 {
    width:715px !important;
  }
.max-width-5-71 {
    max-width:715px !important;
  }
.min-width-5-71 {
    min-width:715px !important;
  }
.height-71 {
    height:710px !important;
  }
.max-height-71 {
    max-height:710px !important;
  }
.min-height-71 {
    min-height:710px !important;
  }
.width-72 {
    width:720px !important;
  }
.max-width-72 {
    max-width:720px !important;
  }
.min-width-72 {
    min-width:720px !important;
  }
.width-5-72 {
    width:725px !important;
  }
.max-width-5-72 {
    max-width:725px !important;
  }
.min-width-5-72 {
    min-width:725px !important;
  }
.height-72 {
    height:720px !important;
  }
.max-height-72 {
    max-height:720px !important;
  }
.min-height-72 {
    min-height:720px !important;
  }
.width-73 {
    width:730px !important;
  }
.max-width-73 {
    max-width:730px !important;
  }
.min-width-73 {
    min-width:730px !important;
  }
.width-5-73 {
    width:735px !important;
  }
.max-width-5-73 {
    max-width:735px !important;
  }
.min-width-5-73 {
    min-width:735px !important;
  }
.height-73 {
    height:730px !important;
  }
.max-height-73 {
    max-height:730px !important;
  }
.min-height-73 {
    min-height:730px !important;
  }
.width-74 {
    width:740px !important;
  }
.max-width-74 {
    max-width:740px !important;
  }
.min-width-74 {
    min-width:740px !important;
  }
.width-5-74 {
    width:745px !important;
  }
.max-width-5-74 {
    max-width:745px !important;
  }
.min-width-5-74 {
    min-width:745px !important;
  }
.height-74 {
    height:740px !important;
  }
.max-height-74 {
    max-height:740px !important;
  }
.min-height-74 {
    min-height:740px !important;
  }
.width-75 {
    width:750px !important;
  }
.max-width-75 {
    max-width:750px !important;
  }
.min-width-75 {
    min-width:750px !important;
  }
.width-5-75 {
    width:755px !important;
  }
.max-width-5-75 {
    max-width:755px !important;
  }
.min-width-5-75 {
    min-width:755px !important;
  }
.height-75 {
    height:750px !important;
  }
.max-height-75 {
    max-height:750px !important;
  }
.min-height-75 {
    min-height:750px !important;
  }
.width-76 {
    width:760px !important;
  }
.max-width-76 {
    max-width:760px !important;
  }
.min-width-76 {
    min-width:760px !important;
  }
.width-5-76 {
    width:765px !important;
  }
.max-width-5-76 {
    max-width:765px !important;
  }
.min-width-5-76 {
    min-width:765px !important;
  }
.height-76 {
    height:760px !important;
  }
.max-height-76 {
    max-height:760px !important;
  }
.min-height-76 {
    min-height:760px !important;
  }
.width-77 {
    width:770px !important;
  }
.max-width-77 {
    max-width:770px !important;
  }
.min-width-77 {
    min-width:770px !important;
  }
.width-5-77 {
    width:775px !important;
  }
.max-width-5-77 {
    max-width:775px !important;
  }
.min-width-5-77 {
    min-width:775px !important;
  }
.height-77 {
    height:770px !important;
  }
.max-height-77 {
    max-height:770px !important;
  }
.min-height-77 {
    min-height:770px !important;
  }
.width-78 {
    width:780px !important;
  }
.max-width-78 {
    max-width:780px !important;
  }
.min-width-78 {
    min-width:780px !important;
  }
.width-5-78 {
    width:785px !important;
  }
.max-width-5-78 {
    max-width:785px !important;
  }
.min-width-5-78 {
    min-width:785px !important;
  }
.height-78 {
    height:780px !important;
  }
.max-height-78 {
    max-height:780px !important;
  }
.min-height-78 {
    min-height:780px !important;
  }
.width-79 {
    width:790px !important;
  }
.max-width-79 {
    max-width:790px !important;
  }
.min-width-79 {
    min-width:790px !important;
  }
.width-5-79 {
    width:795px !important;
  }
.max-width-5-79 {
    max-width:795px !important;
  }
.min-width-5-79 {
    min-width:795px !important;
  }
.height-79 {
    height:790px !important;
  }
.max-height-79 {
    max-height:790px !important;
  }
.min-height-79 {
    min-height:790px !important;
  }
.width-80 {
    width:800px !important;
  }
.max-width-80 {
    max-width:800px !important;
  }
.min-width-80 {
    min-width:800px !important;
  }
.width-5-80 {
    width:805px !important;
  }
.max-width-5-80 {
    max-width:805px !important;
  }
.min-width-5-80 {
    min-width:805px !important;
  }
.height-80 {
    height:800px !important;
  }
.max-height-80 {
    max-height:800px !important;
  }
.min-height-80 {
    min-height:800px !important;
  }
.width-81 {
    width:810px !important;
  }
.max-width-81 {
    max-width:810px !important;
  }
.min-width-81 {
    min-width:810px !important;
  }
.width-5-81 {
    width:815px !important;
  }
.max-width-5-81 {
    max-width:815px !important;
  }
.min-width-5-81 {
    min-width:815px !important;
  }
.height-81 {
    height:810px !important;
  }
.max-height-81 {
    max-height:810px !important;
  }
.min-height-81 {
    min-height:810px !important;
  }
.width-82 {
    width:820px !important;
  }
.max-width-82 {
    max-width:820px !important;
  }
.min-width-82 {
    min-width:820px !important;
  }
.width-5-82 {
    width:825px !important;
  }
.max-width-5-82 {
    max-width:825px !important;
  }
.min-width-5-82 {
    min-width:825px !important;
  }
.height-82 {
    height:820px !important;
  }
.max-height-82 {
    max-height:820px !important;
  }
.min-height-82 {
    min-height:820px !important;
  }
.width-83 {
    width:830px !important;
  }
.max-width-83 {
    max-width:830px !important;
  }
.min-width-83 {
    min-width:830px !important;
  }
.width-5-83 {
    width:835px !important;
  }
.max-width-5-83 {
    max-width:835px !important;
  }
.min-width-5-83 {
    min-width:835px !important;
  }
.height-83 {
    height:830px !important;
  }
.max-height-83 {
    max-height:830px !important;
  }
.min-height-83 {
    min-height:830px !important;
  }
.width-84 {
    width:840px !important;
  }
.max-width-84 {
    max-width:840px !important;
  }
.min-width-84 {
    min-width:840px !important;
  }
.width-5-84 {
    width:845px !important;
  }
.max-width-5-84 {
    max-width:845px !important;
  }
.min-width-5-84 {
    min-width:845px !important;
  }
.height-84 {
    height:840px !important;
  }
.max-height-84 {
    max-height:840px !important;
  }
.min-height-84 {
    min-height:840px !important;
  }
.width-85 {
    width:850px !important;
  }
.max-width-85 {
    max-width:850px !important;
  }
.min-width-85 {
    min-width:850px !important;
  }
.width-5-85 {
    width:855px !important;
  }
.max-width-5-85 {
    max-width:855px !important;
  }
.min-width-5-85 {
    min-width:855px !important;
  }
.height-85 {
    height:850px !important;
  }
.max-height-85 {
    max-height:850px !important;
  }
.min-height-85 {
    min-height:850px !important;
  }
.width-86 {
    width:860px !important;
  }
.max-width-86 {
    max-width:860px !important;
  }
.min-width-86 {
    min-width:860px !important;
  }
.width-5-86 {
    width:865px !important;
  }
.max-width-5-86 {
    max-width:865px !important;
  }
.min-width-5-86 {
    min-width:865px !important;
  }
.height-86 {
    height:860px !important;
  }
.max-height-86 {
    max-height:860px !important;
  }
.min-height-86 {
    min-height:860px !important;
  }
.width-87 {
    width:870px !important;
  }
.max-width-87 {
    max-width:870px !important;
  }
.min-width-87 {
    min-width:870px !important;
  }
.width-5-87 {
    width:875px !important;
  }
.max-width-5-87 {
    max-width:875px !important;
  }
.min-width-5-87 {
    min-width:875px !important;
  }
.height-87 {
    height:870px !important;
  }
.max-height-87 {
    max-height:870px !important;
  }
.min-height-87 {
    min-height:870px !important;
  }
.width-88 {
    width:880px !important;
  }
.max-width-88 {
    max-width:880px !important;
  }
.min-width-88 {
    min-width:880px !important;
  }
.width-5-88 {
    width:885px !important;
  }
.max-width-5-88 {
    max-width:885px !important;
  }
.min-width-5-88 {
    min-width:885px !important;
  }
.height-88 {
    height:880px !important;
  }
.max-height-88 {
    max-height:880px !important;
  }
.min-height-88 {
    min-height:880px !important;
  }
.width-89 {
    width:890px !important;
  }
.max-width-89 {
    max-width:890px !important;
  }
.min-width-89 {
    min-width:890px !important;
  }
.width-5-89 {
    width:895px !important;
  }
.max-width-5-89 {
    max-width:895px !important;
  }
.min-width-5-89 {
    min-width:895px !important;
  }
.height-89 {
    height:890px !important;
  }
.max-height-89 {
    max-height:890px !important;
  }
.min-height-89 {
    min-height:890px !important;
  }
.width-90 {
    width:900px !important;
  }
.max-width-90 {
    max-width:900px !important;
  }
.min-width-90 {
    min-width:900px !important;
  }
.width-5-90 {
    width:905px !important;
  }
.max-width-5-90 {
    max-width:905px !important;
  }
.min-width-5-90 {
    min-width:905px !important;
  }
.height-90 {
    height:900px !important;
  }
.max-height-90 {
    max-height:900px !important;
  }
.min-height-90 {
    min-height:900px !important;
  }
.width-91 {
    width:910px !important;
  }
.max-width-91 {
    max-width:910px !important;
  }
.min-width-91 {
    min-width:910px !important;
  }
.width-5-91 {
    width:915px !important;
  }
.max-width-5-91 {
    max-width:915px !important;
  }
.min-width-5-91 {
    min-width:915px !important;
  }
.height-91 {
    height:910px !important;
  }
.max-height-91 {
    max-height:910px !important;
  }
.min-height-91 {
    min-height:910px !important;
  }
.width-92 {
    width:920px !important;
  }
.max-width-92 {
    max-width:920px !important;
  }
.min-width-92 {
    min-width:920px !important;
  }
.width-5-92 {
    width:925px !important;
  }
.max-width-5-92 {
    max-width:925px !important;
  }
.min-width-5-92 {
    min-width:925px !important;
  }
.height-92 {
    height:920px !important;
  }
.max-height-92 {
    max-height:920px !important;
  }
.min-height-92 {
    min-height:920px !important;
  }
.width-93 {
    width:930px !important;
  }
.max-width-93 {
    max-width:930px !important;
  }
.min-width-93 {
    min-width:930px !important;
  }
.width-5-93 {
    width:935px !important;
  }
.max-width-5-93 {
    max-width:935px !important;
  }
.min-width-5-93 {
    min-width:935px !important;
  }
.height-93 {
    height:930px !important;
  }
.max-height-93 {
    max-height:930px !important;
  }
.min-height-93 {
    min-height:930px !important;
  }
.width-94 {
    width:940px !important;
  }
.max-width-94 {
    max-width:940px !important;
  }
.min-width-94 {
    min-width:940px !important;
  }
.width-5-94 {
    width:945px !important;
  }
.max-width-5-94 {
    max-width:945px !important;
  }
.min-width-5-94 {
    min-width:945px !important;
  }
.height-94 {
    height:940px !important;
  }
.max-height-94 {
    max-height:940px !important;
  }
.min-height-94 {
    min-height:940px !important;
  }
.width-95 {
    width:950px !important;
  }
.max-width-95 {
    max-width:950px !important;
  }
.min-width-95 {
    min-width:950px !important;
  }
.width-5-95 {
    width:955px !important;
  }
.max-width-5-95 {
    max-width:955px !important;
  }
.min-width-5-95 {
    min-width:955px !important;
  }
.height-95 {
    height:950px !important;
  }
.max-height-95 {
    max-height:950px !important;
  }
.min-height-95 {
    min-height:950px !important;
  }
.width-96 {
    width:960px !important;
  }
.max-width-96 {
    max-width:960px !important;
  }
.min-width-96 {
    min-width:960px !important;
  }
.width-5-96 {
    width:965px !important;
  }
.max-width-5-96 {
    max-width:965px !important;
  }
.min-width-5-96 {
    min-width:965px !important;
  }
.height-96 {
    height:960px !important;
  }
.max-height-96 {
    max-height:960px !important;
  }
.min-height-96 {
    min-height:960px !important;
  }
.width-97 {
    width:970px !important;
  }
.max-width-97 {
    max-width:970px !important;
  }
.min-width-97 {
    min-width:970px !important;
  }
.width-5-97 {
    width:975px !important;
  }
.max-width-5-97 {
    max-width:975px !important;
  }
.min-width-5-97 {
    min-width:975px !important;
  }
.height-97 {
    height:970px !important;
  }
.max-height-97 {
    max-height:970px !important;
  }
.min-height-97 {
    min-height:970px !important;
  }
.width-98 {
    width:980px !important;
  }
.max-width-98 {
    max-width:980px !important;
  }
.min-width-98 {
    min-width:980px !important;
  }
.width-5-98 {
    width:985px !important;
  }
.max-width-5-98 {
    max-width:985px !important;
  }
.min-width-5-98 {
    min-width:985px !important;
  }
.height-98 {
    height:980px !important;
  }
.max-height-98 {
    max-height:980px !important;
  }
.min-height-98 {
    min-height:980px !important;
  }
.width-99 {
    width:990px !important;
  }
.max-width-99 {
    max-width:990px !important;
  }
.min-width-99 {
    min-width:990px !important;
  }
.width-5-99 {
    width:995px !important;
  }
.max-width-5-99 {
    max-width:995px !important;
  }
.min-width-5-99 {
    min-width:995px !important;
  }
.height-99 {
    height:990px !important;
  }
.max-height-99 {
    max-height:990px !important;
  }
.min-height-99 {
    min-height:990px !important;
  }
.vh-75 {
  height: 75vh !important;
}
.vh-55 {
  height: 55vh !important;
}
.vh-50 {
  height: 50vh !important;
}
.vw-25 {
  width: 25vw !important;
}
.min-vw-25 {
  min-width: 25vw !important;
}
[class^="col"] {
  margin: 0px 0px !important;
  padding: 0px 0px !important;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding: 0rem 0rem !important;
  margin-right: auto;
  margin-left: auto;
}
.navbar {
  margin-right: auto;
  margin-left: auto;
  padding: 0rem 0rem !important;
}
.nav-link {
  padding: 0rem 0rem;
}
.card-header-image {
  width: 6px;
  height: 21px;
  background-color: rgb(0, 94, 184);
  margin-right: 6px;
}
.card-root {
  font-size: 1.0rem;;
  margin: 10px 5px;
  background-color: white;
  overflow: auto;
  width: calc(100% - 10px) !important;
  min-width: calc(100% - 10px) !important;
  margin-bottom: 0px !important;
}
.card-root .toast-header {
    background-color: white !important;
  }
.card-root.button {
    min-height: 60px !important;
    max-height: 60px !important;
    margin-top: 0px !important;
    background-color: rgba(213, 230, 242, 1) !important;
    border-top: none !important;
  }
.card-root.button2 {
    min-height: 90px !important;
    max-height: 90px !important;
    margin-top: 0px !important;
    background-color: rgba(213, 230, 242, 1) !important;
    border-top: none !important;
  }
.card-root.max {
    min-height: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
.card-root.max.m0 {
      margin: 0px 0px !important;
    }
.card-root.pivot-main {
    min-height: calc(100% - 130px);
    max-height: calc(100% - 130px);
  }
.card-root.pivot-main.m0 {
      margin: 0px 0px !important;
    }
.card-root.pivot {
    min-height: calc(100% - 15px);
    max-height: calc(100% - 15px);
  }
.card-root.pivot.m0 {
      margin: 0px 0px !important;
    }
.card-root.p100 {
    min-height: 100%;
    max-height: 100%;
  }
.card-root.p100.m0 {
      margin: 0px 0px !important;
    }
.card-root.header-only {
    min-height: 55px;
    max-height: 55px;
    height: 55px;
    border-bottom: none;
  }
.card-root.header-only .card-body {
      display: none !important;
      padding: 0px !important;
    }
.card-root.max-button {
    min-height: calc(100% - 80px);
    max-height: calc(100% - 80px);
    height: calc(100% - 80px);
    border-bottom: none;
  }
.card-root.max-button .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.max-button2 {
    min-height: calc(100% - 110px);
    max-height: calc(100% - 110px);
    height: calc(100% - 110px);
    border-bottom: none;
  }
.card-root.max-button2 .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.max-button3 {
    min-height: calc(100% - 215px);
    max-height: calc(100% - 215px);
    height: calc(100% - 215px);
    border-bottom: none;
  }
.card-root.max-button3 .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.max-button-report {
    min-height: calc(100% - 155px);
    max-height: calc(100% - 155px);
    height: calc(100% - 155px);
    border-bottom: none;
  }
.card-root.max-button-report .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.max-button-search {
    min-height: calc(100% - 135px);
    max-height: calc(100% - 135px);
    height: calc(100% - 135px);
    border-top: none;
    border-bottom: none;
    margin-top: 0px !important;
  }
.card-root.max-button-search .card-body {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
.card-root.max-button-pivot {
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
    height: calc(100vh - 300px);
    border-bottom: none;
    overflow-y: hidden;
  }
.card-root.max-button-pivot .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.condition {
    min-height: 90px;
    max-height: 90px;
    height: 90px;
    border-bottom: none;
    background-color: #D5E6F2 !important;
  }
.card-root.condition .toast-header {
      background-color: #D5E6F2 !important;
    }
.card-root.condition .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.max-button-process {
    min-height: calc(100% - 160px);
    max-height: calc(100% - 160px);
    height: calc(100% - 160px);
    border-bottom: none;
  }
.card-root.max-button-process .card-body {
      padding-bottom: 0rem !important;
    }
.card-root.popup {
    width: -webkit-max-content !important;
    width: max-content !important;
    max-width: 100% !important;
    box-shadow: none;
    border: none;
    margin: 0px 0px;
  }
.card-root.popup.w-50vw {
      width: 50vw !important;
    }
.card-root.popup.w-75vw {
      width: 75vw !important;
    }
.card-root.popup.h-75vh {
      height: 75vh !important;
      max-height: 630px !important;
    }
.card-root.popup.vw-50vw {
      min-width: 250px !important;
    }
.card-root.popup-button {
    width: auto !important;
    box-shadow: none;
    border: none;
    margin: 0px 0px;
  }
.card-root.popup-button > div {
      display: -webkit-flex;
      display: flex;
    }
.card-root.popup-button.sm {
      width: 300px !important;
    }
.card-root.popup-button.md {
      width: 500px !important;
    }
.card-root.popup-button.lg {
      width: 800px !important;
    }
.card-buttom-button {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 40px;
  min-width: -webkit-max-content;
  min-width: max-content;
}
.card-buttom-button.dent {
    border: 1px solid rgb(0, 157, 209) !important;
    box-shadow:1px 1px 1px 0px rgb(0, 157, 209) inset;
  }
.card-buttom-button2 {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 30px;
  min-width: -webkit-max-content;
  min-width: max-content;
}
.card-buttom-button2.dent {
    border: 1px solid rgb(0, 157, 209) !important;
    box-shadow:1px 1px 1px 0px rgb(0, 157, 209) inset;
  }
[class^="card-header-text"] span {
    margin-right: 2.0rem;
  }
[class^="card-header-text"] {
  font-weight: normal !important;
  color: rgba(33, 37, 41, 1) !important;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.card-header-text-md {
    font-size: 1.0rem !important;
  }
.card-header-text-md .btn-group {
      min-width: 100px;
    }
.card-header-text-lg {
    font-size: 1.15rem !important;
  }
.card-header-subtext {
  color: rgba(120, 120, 117, 1);
  font-size: 1.0rem !important;
  font-weight: normal !important;
  margin-left: 1.0rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.card-header-subtext-gnr {
  color: black !important;
}
.gnr {
  margin-top: 10px;
}
.gnr label {
    margin-left: 5px;
  }
.card-body {
  font-size: inherit !important;
  padding: .5rem !important;
  overflow: auto;
}
.card-body .toast {
    background-color: rgba(247, 247, 250, 1) !important;
  }
.card-body .toast .toast-header {
      background-color: rgba(247, 247, 250, 1) !important;
    }
.card-body .toast.button {
      min-height: 60px !important;
      max-height: 60px !important;
      margin-top: 0px !important;
      background-color: rgba(213, 230, 242, 1) !important;
      border-top: none !important;
    }
.wj-listbox.wj-menu-items .wj-listbox-item.wj-subitems::after {
  color: rgb(0, 157, 209) !important;
}
.split-pane-top {
  height: calc(100vh - 125px) !important;
}
.split-pane-top.full{
    height: calc(100vh - 75px) !important;
  }
.split-pane-top-bi {
  height: calc(100vh - 195px) !important;
}
[data-type="SplitPane"] > div:not([data-type="Resizer"]) {
    overflow: auto;
  }
.btn-blue {
  background-color: rgb(0, 94, 184);
}
.btn-lightblue {
  background-color: rgb(0, 157, 209);
}
.btn-warn {
  background-color: rgb(233, 83, 38);
}
.btn-info {
  background-color: rgb(0, 133, 64);
}
button {
  font-size: 1.15rem !important;
}
button.btn-sm {
    font-size: 1.0rem !important;
  }
.radiobtn-lightblue {
  font-size: 1.0rem;
}
.radiobtn-lightblue.sm {
    width: 50px;
    /* padding: 0.25rem 0.5rem;*/
  }
.radiobtn-lightblue.md {width: 75px;}
.radiobtn-lightblue.lg {width: 100px;}
.radiobtn-lightblue.at {width: auto;}
.radiobtn-lightblue.mx {width: 100%;}
.radiobtn-lightblue input {
    display: none;
  }
.radiobtn-lightblue {
  margin: 0rem 0rem;
  background-color: white !important;
  border-color: rgb(0, 157, 209) !important;
  color: rgba(33, 37, 41, 1) !important;
}
.radiobtn-lightblue.active {
    background-color: rgb(0, 157, 209) !important;
    color: white !important;
  }
.radiobtn-lightblue:hover, .radiobtn-lightblue:disabled {
    background-color: rgba(0, 157, 209, .5) !important;
    color: white !important;
  }
.form-group {
  width: 100%;
  min-width: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
  /* background-color: var(--background-color) !important;*/
  display: inline-block;
}
.form-group .wj-input-group {
    width: 100%;
  }
.form-group .wj-input-group input[type="tel"] {
      margin-top: 3px !important;
    }
.form-control {
  color: rgba(120, 120, 117, 1) !important;
  font-size: 1.0rem !important;
  padding: 0rem 0.5rem !important;
}
.form-label {
  color: rgba(120, 120, 117, 1) !important;
  margin: 0rem 0rem !important;
}
input[type].form-check-input:checked::before {
    left: 1px;
    top: 7px;
    width: 5px;
    height: 2px;
  }
input[type].form-check-input:checked::after {
    left: 7px;
    top: 2px;
    width: 2px;
    height: 9px;
  }
.type-datetime .wj-form-control {
  width: 130px !important;
}
.type-date .wj-form-control {
  width: 100px !important;
}
.type-date1 {
  width: 130px !important;
}
.type-date1-lg {
  width: 140px !important;
}
.type-datetime1 {
  width: 205px !important;
}
.text-suffix {
  background: inherit !important;
  border: none !important;
  padding-top: 0rem !important;
  padding-left: 0.25rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
}
.text-suffix-multi {
  background: inherit !important;
  border: none !important;
  padding-top: 0rem !important;
  padding-left: 0.125rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  margin-top: -7px !important;
}
input::-webkit-input-placeholder {
  font-family: 'Material Icons Outlined';
  font-size: 1.5rem;
  position: relative;
  top: .5rem;
}
input:-ms-input-placeholder {
  font-family: 'Material Icons Outlined';
  font-size: 1.5rem;
  position: relative;
  top: .5rem;
}
input::placeholder {
  font-family: 'Material Icons Outlined';
  font-size: 1.5rem;
  position: relative;
  top: .5rem;
}
.grid-button {
  color: rgb(0, 94, 184) !important;
  float: right;
  cursor: pointer;
  overflow: hidden;
  background-color: inherit;
}
.grid-button.setting {
    /* color: $text-color !important;*/
    /* border: $outline !important;*/
  }
.grid-button.edit {
    border: none;
    margin-top: -3px;
    padding-right: 0px;
    z-index: 9999;
  }
.grid-button [class^="material-icons"]{
    font-size: 1.5rem !important;
  }
.grid-button:hover, .grid-button:disabled {
    opacity: .5;
  }
.wj-glyph-calendar, .wj-glyph-clock{
  color: rgb(0, 157, 209) !important;
}
/* .wj-state-focused,*/
/* .form-control, input[type]:not(.wj-form-control), button:not(.wj-btn-glyph), a {*/
.form-control:focus, input[type]:not(.wj-form-control):focus, button:not(.wj-btn-glyph):focus, a:focus {
    border-color: rgb(0, 157, 209) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 209, .5) !important;
  }
input.btn-check:focus+label{
      border-color: rgb(0, 157, 209) !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.2rem rgba(0, 157, 209, .5) !important;
    }
div.wj-control.wj-inputnumber, div.wj-control.wj-inputmask {
    min-height: 33.5px !important;
  }
div.wj-control.wj-inputnumber .wj-input .wj-input-group .wj-btn, div.wj-control.wj-inputmask .wj-input .wj-input-group .wj-btn {
      min-height: 33.5px !important;
    }
div.wj-control.wj-inputnumber, div.wj-control.wj-inputdate, div.wj-control.wj-inputmask, div.wj-control.wj-multi-autocomplete, div.wj-control.wj-combobox:not(.wj-menu,.ticket-status) {
    color: gray !important;
  }
div.wj-control.wj-inputnumber:focus-within, div.wj-control.wj-inputdate:focus-within, div.wj-control.wj-inputmask:focus-within, div.wj-control.wj-multi-autocomplete:focus-within, div.wj-control.wj-combobox:not(.wj-menu,.ticket-status):focus-within {
      border-color: rgb(0, 157, 209) !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.2rem rgba(0, 157, 209, .5) !important;
    }
div.wj-control.wj-inputnumber.wj-state-disabled, div.wj-control.wj-inputdate.wj-state-disabled, div.wj-control.wj-inputmask.wj-state-disabled, div.wj-control.wj-multi-autocomplete.wj-state-disabled, div.wj-control.wj-combobox:not(.wj-menu,.ticket-status).wj-state-disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
.was-validated .form-control:invalid, .form-control.is-invalid{
  background-image: inherit;
}
.wj-control.is-invalid {
  border-color: #dc3545 !important;
}
/* .text-muted.form-text {*/
/*   color: red !important;*/
/* }*/
.wj-form-control {
  margin: 0rem 0rem !important;
  padding: 0rem 0.5rem !important;
  min-height: 25px !important;
}
.wj-control.wj-content.wj-dropdown {
  /* margin: 0rem 0rem !important;*/
  padding: 0.25rem 0rem !important;
}
.wj-token {
  margin: 0rem .125rem !important;
}
.wj-token-label {
  padding: 0.125rem 0.25rem !important;
}
.wj-input-group-btn .wj-btn {
  min-height: 25px !important;
}
.wj-glyph-filter, .wj-glyph-pin {
  top: -.3em !important;
  color: rgb(0, 94, 184) !important;
}
.wj-glyph {
  color: rgb(0, 94, 184) !important;
}
.wj-cell.wj-state-multi-selected {
  background: rgb(0, 157, 209) !important;
  /* border: 2px solid $blue;*/
  /* &:nth-child(1) {*/
  /*   border-right: none;*/
  /* }*/
  /* &:nth-child(n+2) {*/
  /*   border-left: none;*/
  /* }*/
}
.wj-cell.wj-state-active {
  /* border: 2px solid $blue;*/
  transition: all 100ms;
  background-color: rgb(0, 94, 184);
  color: white !important;
}
.split-pane-top-bi .wj-cell.wj-state-active {
    /* border: 2px solid $blue;*/
    /* transition: all 100ms;*/
    /* background-color: $blue;*/
    color: black !important;
  }
.cjjWdp {
    background: rgb(0, 157, 209) !important;
    opacity: 0.1 !important;
}
.fXAXjb {
  width: 1px;
  margin: 0rem 0rem !important;
}
.hEACtv {
  height: 1px;
  margin: 0rem 0rem !important;
}
.tooltip-text {
  color: rgb(0, 157, 209);
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(0, 157, 209);
          text-decoration-color: rgb(0, 157, 209);
  cursor: pointer;
}
.tooltip-text.label{
    font-size: 0.8rem !important;
  }
.date-range-icon {
  color: rgb(0, 157, 209) !important;
  border: none !important;
  background-color: inherit !important;
  opacity: 1 !important;
  padding: 0rem 0rem;
}
.date-range-icon:focus {
    box-shadow: none !important;
  }
.date-range-icon:hover {
    color: rgba(0, 157, 209, .5) !important;
  }
.date-range-icon:disabled {
    color: rgba(0, 157, 209, .5) !important;
  }
.wj-calendar td:hover, .wj-calendar td:disabled {
    background-color: rgba(247, 247, 250, 1);
    color: rgb(0, 157, 209);
  }
.calendar-range {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: none;
}
.button-gray {
  background: transparent linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #a6a6a8 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: rgba(120, 120, 117, 1) !important;
}
.button-gray:hover, .button-gray:disabled {
    opacity: 0.5 !important;
  }
.button-gray.small {
    font-size: 1rem !important;
  }
.button-blue {
  background: transparent linear-gradient(180deg, rgb(0, 94, 184) 0%, #00478B 100%) 0% 0% no-repeat padding-box !important;
  border-color: rgb(0, 94, 184) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: white !important;
}
.button-blue:hover, .button-blue:disabled {
    opacity: 0.5 !important;
  }
.button-warn {
  background: transparent linear-gradient(180deg, rgba(247, 168, 39, 1) 0%, rgb(233, 83, 38) 100%) 0% 0% no-repeat padding-box !important;
  border-color: rgb(233, 83, 38) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: white !important;
}
.button-warn:hover, .button-warn:disabled {
    opacity: 0.5 !important;
  }
.button-info {
  background: transparent linear-gradient(180deg, rgba(0, 187, 90, 1) 0%, rgb(0, 133, 64) 100%) 0% 0% no-repeat padding-box !important;
  border-color: rgb(0, 133, 64) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: white !important;
}
.button-info:hover, .button-info:disabled {
    opacity: 0.5 !important;
  }
.button-menu {
  font-size: 1.15rem !important;
  background-color: white !important;
  color: rgb(0, 94, 184) !important;
  border-color: rgba(120, 120, 117, 1) !important;
  opacity: 1 !important;

}
.button-menu.active {
    background-color: rgb(0, 94, 184) !important;
    color: white !important;
  }
.button-menu:hover {
    background-color: rgb(0, 157, 209) !important;
    color: white !important;
  }
.button-menu:disabled {
    background-color: rgba(120, 120, 117, 1) !important;
    color: white !important;
  }
.button-menu {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.dropdown-item:active {
    background-color: rgb(0, 157, 209) !important;
  }
.menu-icon {
  margin-right: 0.5rem;
  text-align: left;
}
.menu-text {
  text-align: left;
}
.menu-arrow {
  margin-left: auto;
}
.menu-right-flex {
  -webkit-flex: 1 0 100%;
          flex: 1 0 100%;
}
.list-header {
  height: 30px;
}
.list-header.filter {
    height: 65px;
  }
.list-header.production {
    height: 110px;
  }
.list-header.fare {
    height: 60px;
  }
.list-header {
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.list-header .list-count {
    color: rgba(233, 83, 38, 1);
    font-size: 1.5rem;
  }
.list-header [class^="material-icons"].settings {
    /* color: rgba(233, 83, 38, 1);*/
    cursor: pointer;
  }
.list-header [class^="wj-glyph-filter"] {
    /* color: rgba(233, 83, 38, 1);*/
    cursor: text;
  }
.list-body {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.process-detail-list-body {
  height: 50vh;
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.icon-center {
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
}
#list-settings .wj-nodelist {
    padding: .5rem 0rem !important;
  }
#list-settings .wj-node {
    border: 1px solid rgba(166, 166, 166, 1);
  }
.wj-treeview .wj-nodelist .wj-node {
  font-size: inherit;
  font-weight: normal;
  color: rgb(0, 94, 184);
  background: inherit;
  box-shadow: none;
  padding: 4px 6px 3px 6px !important;
}
.wj-treeview .wj-nodelist .wj-node::before {
    color: rgb(0, 157, 209);
    opacity: 1;
  }
.wj-treeview .wj-nodelist .wj-node.wj-state-selected{
    background-color: rgb(0, 157, 209);
    color: white;
  }
.wj-treeview .wj-nodelist .wj-node.wj-state-selected::before {
      color: white;
      opacity: 1;
    }
.form-label {
  font-size: 0.8rem !important;
}
.form-label.lg {
    font-size: 1.1rem !important;
  }
.label-required{
  color: red !important;
  /* &::after {*/
  /*   content: '(必須)';*/
  /* }*/
}
.message, .swal2-title {
  font-size: 1.1rem !important;
}
.message .error, .swal2-title .error {
    color: red;
    white-space: pre-wrap;
  }
.swal2-popup.swal2-toast.swal2-icon-info{
    background-color: rgb(235, 240, 245);
  }
.swal2-popup.swal2-toast.swal2-icon-error{
    background-color: rgb(245, 225, 225);
  }
.swal2-cancel.swal2-styled {
  min-width: 120px;
  background: transparent linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #a6a6a8 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: rgba(120, 120, 117, 1) !important;
}
.swal2-cancel.swal2-styled:hover, .swal2-cancel.swal2-styled:disabled {
    opacity: 0.5 !important;
  }
.swal2-confirm.swal2-styled {
  min-width: 120px;
  background: transparent linear-gradient(180deg, rgb(0, 94, 184) 0%, #00478B 100%) 0% 0% no-repeat padding-box !important;
  border-color: rgb(0, 94, 184) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: white !important;
}
.swal2-confirm.swal2-styled:hover, .swal2-confirm.swal2-styled:disabled {
    opacity: 0.5 !important;
  }
.swal2-popup.swal2-toast .swal2-actions {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
.wj-flexgrid .cell-changed {
    background-color: #d5edd5;
    /* &.wj-state-selected {*/
    /*   background-color: black;*/
    /* }*/
    /* border: 2px solid lightpink;*/
  }
.wj-flexgrid .cell-added {
    background-color: lightyellow;
    /* border-top-style: solid;*/
    /* border-top-color: lightyellow;*/
    /* border-top-width: 2px;*/
    /* border-bottom-style: solid;*/
    /* border-bottom-color: lightyellow;*/
    /* border-bottom-width: 2px;*/
    /* &:nth-child(1) {*/
    /*   border-left-color: lightyellow;*/
    /*   border-left-width: 2px;*/
    /* }*/
    /* &:nth-child(n+2) {*/
    /*   border-right-color: lightyellow;*/
    /*   border-right-width: 2px;*/
    /* }*/
  }
.wj-flexgrid .cell-readonly:not(.wj-header):not(.wj-state-selected) {
    color: rgba(120, 120, 117, 1) !important;
    background-color: #e9ecef;
  }
.wj-flexgrid .cell-gray:not(.wj-header):not(.wj-state-selected) {
    color: rgba(120, 120, 117, 1) !important;
    background-color: #e9ecef;
  }
.wj-flexgrid .cell-minus:not(.wj-header) {
    color: red !important;
  }
.swal2-container {
  z-index: 2000 !important;
}
[isreadonly="true"].wj-state-readonly {
  background-color: #e9ecef;
}
.card-error-message {
  padding: 1.0rem 0rem;
  color: red;
}
.wj-tabheader {
  min-width: 8.0rem;
  background-color: lightgray !important;
}
.wj-tabheader.wj-state-active {
    color: white !important;
    background-color: rgb(0, 94, 184) !important;
  }
.wj-tabheader:hover {
    color: white !important;
    background-color: rgb(0, 157, 209) !important;
  }
/* .wj-tabpanel.tab-headers .wj-tabpanes {*/
/*   border-color: $blue;*/
/* }*/
/* .wj-tabpanel.tab-headers > div > .wj-tabheaders {*/
/*   background: white;*/
/*   color: $lightblue;*/
/*   border: none;*/
/* }*/
/* .wj-tabpanel.tab-headers .wj-tabheaders .wj-tabheader.wj-state-active {*/
/*     background: $blue;*/
/*     border-radius: 0.25rem 0.25rem 0px 0px;*/
/*     color: white;*/
/* }*/
/* .wj-tabpanel.tab-headers .wj-tabheaders .wj-tabheader.wj-state-active:after {*/
/*     display: none; *//* hide underline *//**/
/* }*/
.refButton {
  padding: 0.125rem;
  background: transparent linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #a6a6a8 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: rgba(120, 120, 117, 1) !important;
}
.refButton:hover, .refButton:disabled {
    opacity: 0.5 !important;
  }
.refButton.disabled {
    opacity: 0.5 !important;
  }
.form-check-inline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}
.wj-dialog-header.master-import {
    width: 500px !important;
  }
.wj-dialog-header.mnt-login {
    width: 600px !important;
  }
.modal-master {
  /* top: 10px !important;*/
  height: 90vh;
  width: 90vw;
}
.modal-header .close {
  padding: .5rem .5rem !important;
  margin: -.5rem -.5rem !important;
  border: 1px solid #a6a6a8 !important;
  border-radius: 4px !important;
}
.modal-header .close:hover {
    background-color: rgba(166, 166, 168, .2);
  }
.wj-cell button.wj-cell-maker {
    border: 1px solid #a6a6a8 !important;
  }
.wj-cell button.wj-cell-maker:hover {
      /* color: $text-color !important;*/
      opacity: .75;
    }
button.wj-cell-maker:disabled {
  opacity: .5 !important;
}
.error-title {
  font-size: 2.0rem;
}
.error-text {
  font-size: 1.0rem;
}
.panel-card-body {
  height: calc(100vh - 450px);
}
.panel-card-body.error {
    height: calc(100vh - 510px);
  }
.warn-void {
  background-color: #FFE0B2 !important;
}
.alert-void {
  background-color: #EF9A9A !important;
}
.main-panel-list {
  height: 300px;
}
.main-panel-list.sub {
    height: 250px;
  }
.badge-color.warn {
    background-color: rgb(233, 83, 38) !important;
    color: white !important;
  }
.badge-color.info {
    background-color: rgb(0, 133, 64) !important;
    color: white !important;
  }
.badge-color.blue {
    background-color: rgb(0, 94, 184) !important;
    color: white !important;
  }
.badge-color.yellow {
    background-color: #d1d111 !important;
    color: white !important;
  }
.badge-color.search {
    font-size: .85rem !important;
    background-color: #E5E5E5 !important;
    color: rgba(120, 120, 117, 1) !important;
  }
.info-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.1rem;
  overflow: hidden;
  width: calc(100% - 30px);
  display: inline-block;
}
.info-title.all {
    text-overflow: unset;
    white-space: unset;
  }
.info-title:not(.no-url) {
  color: rgb(0, 94, 184);
}
.info-title-box {
  border-bottom: #a6a6a8;
  border-bottom-style: solid;
  border-bottom-width: 0.125rem;
}
.info-title-box:not(.no-url) {
  cursor: pointer;
}
.info-caret {
  color: rgb(0, 157, 209) !important;
  font-size: 2.0rem !important;
  margin-left: 0rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
}
.info-caret :hover {
    color: rgb(0, 157, 209) !important;
  }
.old-info {
  color: rgb(0, 94, 184) !important;
  font-size: 1.0rem !important;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
}
.old-info .info-caret {
    margin-top: 0.0rem;
    margin-bottom: 0.0rem;
  }
.tkt-cnt-error {
  background-color: rgba(128, 0, 0, .5);
  border: 1px solid maroon !important;
  border-radius: .5rem !important;
  color: maroon !important;
  padding: .5rem .5rem .5rem .5rem;
  height: 60px;
}
.info-tkt-count {
  font-size: 1.5rem;
  color: rgb(0, 94, 184);
}
.info-tkt-count-progress {
  width: calc(100% - 110px)
}
.info-main-list {
  height: calc(100vh - 180px);
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.over-flow-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.over-flow-x {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
.over-flow-xy {
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.form-group {
  min-width: 10px !important;
}
.ft-margin {
  margin-top: 28px;
  margin-left: 8px;
  margin-right: 8px;
}
.text-indent-200p {
  text-indent: 200% !important;
}
.card-condition-item ::-webkit-input-placeholder {
    font-size: 0.75rem !important;
    color: rgba(120, 120, 117, 1) !important;
  }
.card-condition-item :-ms-input-placeholder {
    font-size: 0.75rem !important;
    color: rgba(120, 120, 117, 1) !important;
  }
.card-condition-item ::placeholder {
    font-size: 0.75rem !important;
    color: rgba(120, 120, 117, 1) !important;
  }
.ml-minus10 {
  margin-left: -10px;
}
.scroll-x-hidden {
  overflow-x: hidden !important;
}
.scroll-y-hidden {
  overflow-y: hidden !important;
}
#tktNdcFlg {
  -webkit-transform: scale(1.5, 1.5) !important;
          transform: scale(1.5, 1.5) !important;
  margin-top: 10px !important;
  margin-left: 5px !important;
  background-color: white !important;
}
#tktNdcFlg:checked {
    background-color: rgb(0, 157, 209) !important;
  }
#tktNdcFlg:disabled {
    background-color: #eee;
    opacity: .5;
  }
input[type="checkbox"]:disabled {
    background-color: #bbb;
    opacity: .5;
  }
div[wj-part="dropdown"] div.wj-listbox-item:first-child .linktype{
    color: rgb(0, 157, 209) !important;
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(0, 157, 209);
            text-decoration-color: rgb(0, 157, 209);
  }
.minus-number input {
  color: red !important;
}
.plus-number input {
  color: rgba(120, 120, 117, 1) !important;
}
.width1280 {
  min-width: 1280px !important;
}
#migration-popup .card-root {
  /* height: 700px !important;*/
  max-height: 75vh !important;
  width: 1024px !important;
  max-width: 1024px !important;
}
.color-red{
  color: deeppink;
}
.color-text{
  color: rgba(120, 120, 117, 1);
}
.ctx-menu {
  /* padding: 5px;*/
  min-width: 120px;
}
.popup-error-text {
  font-size: 1.25rem;
  color: red;
}
.popup-warn-text {
  color: red;
}
.print-flags {
  border-color: rgba(120, 120, 117, 1) !important;
  border-style: solid !important;
  border-width: thin !important;
  padding: 5px !important;
}
.prev-datamaint {
  border-color: rgba(120, 120, 117, 1) !important;
  border-style: solid !important;
  border-width: thin !important;
  padding: 0px 0px 5px 5px !important;
}
.ml-320px {
  margin-left: 320px !important;
}
#edit-account1-pane .wj-content {
    border-radius: 0px !important;
  }
#edit-account2-pane .wj-content {
    border-radius: 0px !important;
  }
[id^="menu-item-"] {
  color: rgb(0, 94, 184) !important;
  text-align: center;
  width: auto;
  min-width: 80px;
  max-width: 180px;
  font-weight: normal;
  font-size: 0.75rem;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  border: none !important;
  background-color: rgba(247, 247, 250, 1) !important;
  border-radius: 0% !important;
}
[id^="menu-item-"] .wj-form-control {
    padding: 0rem 0rem !important;
  }
[id^="menu-item-"] :hover {
    color: rgba(0, 94, 184, .5);
    background-color: rgba(227, 227, 230, 1);
  }
[id^="menu-item-"]::after {
    display: none !important;
  }
#menu-item-user {
  color: rgb(0, 94, 184) !important;
  text-align: center;
  width: auto;
  min-width: 100px;
  max-width: 200px;
  font-weight: normal;
  font-size: 0.75rem;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  border: none !important;
  background-color: rgba(247, 247, 250, 1) !important;
  border-radius: 0% !important;
}
#menu-item-user .wj-form-control {
    padding: 0rem 0rem !important;
    cursor: text !important;
  }
#menu-item-user :hover {
    color: inherit !important;
    background-color: inherit !important;
  }
#menu-item-user::after {
    display: none !important;
  }
#menu-item-main {
  border-right: 1px solid rgba(166, 166, 168, 1) !important;
}
#menu-item-end {
  border-left: 1px solid rgba(166, 166, 168, 1) !important;
}
div[role="menu"] .wj-listbox-item {
    color: rgb(0, 94, 184);
    font-size: 0.75rem !important;
  }
div[role="menu"] .wj-listbox-item.wj-state-selected {
      color: white !important;
    }
.caret {
  color: rgb(0, 157, 209) !important;
  font-size: 2.0rem !important;
  margin-left: 0rem;
}
.caret :hover {
    color: rgb(0, 157, 209) !important;
  }
.menu-top {
  /* position: fixed !important;*/
  /* z-index: 100 !important;*/
  background: rgba(247, 247, 250, 1) !important;
  width: calc(100% - 0.4rem) !important;
  height: 50px !important;
  overflow: auto !important;
  margin-left: 0.4rem !important;
}
/* .menu-user {*/
/*   margin-left: auto;*/
/* }*/
.menu-alert {
  margin-left: auto;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
/* .badge {*/
/*   position: fixed !important;*/
/*   vertical-align: top !important;*/
/*   background-color: $badge-background-color !important;*/
/*   color: $badge-color !important;*/
/*   :hover {*/
/*     color: $badge-color !important;*/
/*   }*/
/* }*/
.footer {
  /* position: fixed !important;*/
  /* z-index: 100 !important;*/
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  background: rgba(229, 229, 229, 1) !important;
  width: 100% !important;
  height: 35px !important;
}
.footer a {
    color: rgb(0, 157, 209) !important;
  }
.footer a:hover {
      color: rgb(0, 94, 184) !important;
    }
.footer [class^='material-icons'] {
  font-size: 1.2rem !important;
}
.footer-alert {
  font-size: 0.75rem;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 1rem !important;
  margin: 0px 0px !important;
  width: auto !important;
}
.footer-alert .input-group-text {
    padding: 0rem 0rem !important;
    border-right: none !important;
    background-color: white !important;
  }
.footer-alert [class^='material-icons'].ok {
      color: rgb(0, 133, 64) !important;
    }
.footer-alert [class^='material-icons'].stop {
      color: rgb(233, 83, 38) !important;
    }
.footer-alert [class^='material-icons'].error {
      color: red !important;
    }
.footer-alert .footer-alert-text {
    border-left: none;
    background-color: white !important;
  }
.footer-link {
  margin-left: auto;
  margin-right: auto;
}
.contentTitle {
  height: 40px;
  background-color: rgba(155, 202, 235, 1);
}
.contentTitle .title-column {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
  }
.contentTitle .title-column .title-text {
      font-size: 1.4rem;
      font-weight: normal;
    }
.title-breadcrumb {
  margin: 0rem 0rem !important;
  padding: 0rem 1.0rem !important;
  margin-left: auto !important;
}
.title-breadcrumb .breadcrumb {
    margin: 0rem 0rem !important;
    padding: 0rem 1.0rem !important;
    background-color: inherit !important;
  }
.title-icon {
  color: rgb(0, 94, 184);
}
.processing {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}
.processing .processing-icon {
    position: fixed;
    top: 40%;
    left: 45%;
    width: 10rem !important;
    height: 10rem !important;
    border-width: 1.0rem !important;
    opacity: .5 !important;
    color: rgb(0, 157, 209) !important;
  }
.processing .processing-message {
    position: fixed;
    top: 30%;
    left: 40%;
    color: white !important;
    font-size: xx-large;
  }
.processing-title {
  text-align: center;
  font-size: x-large;
  border: 1px solid rgb(0, 94, 184) !important;
  box-shadow:6px 5px 12px -1px rgb(0, 157, 209) inset;
}
.processing-circle {
  width: 75px;
  height: 75px;
  color: rgb(0, 157, 209);
}
.card-condition-item {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: start;
          align-items: start;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  min-height: 30px;
  width: calc(100% - 80px);
}
/* margin-top: .5rem;*/
.card-condition-item .btn-group {
    padding: 0rem 0rem;
    padding-bottom: .2rem;
  }
.card-condition-item .inner {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
.card-condition-item.wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
/* level 0 nodes (headers) */
.accordion.wj-treeview .wj-nodelist>.wj-node {
  font-size: inherit;
  font-weight: normal;
  color: white;
  background: rgb(0, 94, 184);
  padding: 4px 6px 3px 6px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.accordion.wj-treeview .wj-nodelist>.wj-node::before {
    color: white;
    opacity: 1;
  }
.accordion.wj-treeview .wj-nodelist>.wj-node input {
    display: none;
  }
.accordion.wj-treeview .wj-nodelist>.wj-node.wj-state-empty input {
    border-color: rgb(0, 94, 184) !important;
    display: inline-block;
  }
/* level 1 nodes (navigation items) */
.accordion.wj-treeview .wj-nodelist>.wj-nodelist>.wj-node {
  font-size: inherit;
  font-weight: normal;
  color: rgb(0, 94, 184);
  background: inherit;
  box-shadow: none;
  padding: 4px 6px 3px 6px !important;
}
.accordion.wj-treeview .wj-nodelist>.wj-nodelist>.wj-node::before {
    color: rgb(0, 157, 209);
    opacity: 1;
  }
.accordion.wj-treeview .wj-nodelist>.wj-nodelist>.wj-node:hover {
    background-color: rgba(166, 166, 168, .5);
  }
.accordion.wj-treeview .wj-nodelist>.wj-nodelist>.wj-node:focus {
    background-color: rgba(166, 166, 168, .5);
  }
/* .accordion .wj-node.wj-state-selected {*/
/*   font-style: italic;*/
/*   font-weight: bold !important;*/
/* }*/
/* Checkbox */
.wj-node input[type=checkbox], .wj-cell input[type=checkbox], .form-check input[type=checkbox], .wj-listbox-item input[type=checkbox] {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    height: 14px;
    width: 14px;
    vertical-align: -0.2rem;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: .4rem;
    margin-bottom: 0rem;
    outline: none;
    border:1px solid rgba(166, 166, 166, 1) !important;
    border-radius: 3px;
    float: none;
    /* &:focus {*/
    /*   color: #495057;*/
    /*   background-color: #fff;*/
    /*   border-color: $lightblue;*/
    /*   outline: 0;*/
    /*   box-shadow: 0 0 0 0.2rem $lightblue-alpha50;*/
    /* }*/
  }
.wj-node input[type=checkbox]::before, .wj-node input[type=checkbox]::after, .wj-cell input[type=checkbox]::before, .wj-cell input[type=checkbox]::after, .form-check input[type=checkbox]::before, .form-check input[type=checkbox]::after, .wj-listbox-item input[type=checkbox]::before, .wj-listbox-item input[type=checkbox]::after {
      position: absolute;
      content: "";
      background: #fff;
    }
.wj-node input[type=checkbox]::before, .wj-cell input[type=checkbox]::before, .form-check input[type=checkbox]::before, .wj-listbox-item input[type=checkbox]::before {
      left: 2px;
      top: 6px;
      width: 0;
      height: 2px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
.wj-node input[type=checkbox]::after, .wj-cell input[type=checkbox]::after, .form-check input[type=checkbox]::after, .wj-listbox-item input[type=checkbox]::after {
      right: 9px;
      bottom: 3px;
      width: 2px;
      height: 0;
      transform: rotate(40deg);
      -webkit-transform: rotate(40deg);
      -moz-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      -o-transform: rotate(40deg);
    }
.wj-node input[type=checkbox]:checked, .wj-cell input[type=checkbox]:checked, .form-check input[type=checkbox]:checked, .wj-listbox-item input[type=checkbox]:checked {
      background: rgb(0, 157, 209) !important;
    }
.wj-node input[type=checkbox]:checked::before, .wj-cell input[type=checkbox]:checked::before, .form-check input[type=checkbox]:checked::before, .wj-listbox-item input[type=checkbox]:checked::before {
      left: 1px;
      top: 6px;
      width: 5px;
      height: 2px;
    }
.wj-node input[type=checkbox]:checked::after, .wj-cell input[type=checkbox]:checked::after, .form-check input[type=checkbox]:checked::after, .wj-listbox-item input[type=checkbox]:checked::after {
      right: 3px;
      bottom: 1px;
      width: 2px;
      height: 9px;
    }
.wj-node input[type=checkbox]:indeterminate, .wj-cell input[type=checkbox]:indeterminate, .form-check input[type=checkbox]:indeterminate, .wj-listbox-item input[type=checkbox]:indeterminate {
      background: rgb(0, 157, 209) !important;
    }
.wj-node input[type=checkbox]:indeterminate::before, .wj-node input[type=checkbox]:indeterminate::after, .wj-cell input[type=checkbox]:indeterminate::before, .wj-cell input[type=checkbox]:indeterminate::after, .form-check input[type=checkbox]:indeterminate::before, .form-check input[type=checkbox]:indeterminate::after, .wj-listbox-item input[type=checkbox]:indeterminate::before, .wj-listbox-item input[type=checkbox]:indeterminate::after {
      width: 4px;
      height: 2px;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
    }
.wj-node input[type=checkbox]:indeterminate::before, .wj-cell input[type=checkbox]:indeterminate::before, .form-check input[type=checkbox]:indeterminate::before, .wj-listbox-item input[type=checkbox]:indeterminate::before {
      left: 2px;
      top: 5px;
    }
.wj-node input[type=checkbox]:indeterminate::after, .wj-cell input[type=checkbox]:indeterminate::after, .form-check input[type=checkbox]:indeterminate::after, .wj-listbox-item input[type=checkbox]:indeterminate::after {
      right: 2px;
      bottom: 5px;
    }
.pdiv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}
.pbutton {
  color: rgb(0, 157, 209) !important;
  border: none !important;
  float: right;
  cursor: pointer;
  overflow: hidden;
  background-color: inherit;
  margin-left: 10px !important;
  font-family: 'Material Icons Outlined';
}
#sample-panel1 {
  min-height: 0;
  width: auto;
  /* height: 100%;*/
  height: calc(100% - 120px) !important;
  margin: 0px;
  display: block;
}
/* transform: translate(10px, 10px);*/
#sample-panel1 #panel-item-list {
    max-height: calc(100vh - 155px) !important;
  }
#sample-panel1 #panel-item-list #panel-item-list-inner {
      max-height: 100% !important;
    }
#sample-panel1 #panel-section-list {
    display: none;
  }
#sample-panel1 #panel-defer {
    display: none;
  }
#sample-panel2 {
  min-height: 0;
  margin: 0px;
  display: block;
  height: inherit !important;
  /* #panel-defer {*/
  /*   display: none;*/
  /* }*/
}
#sample-panel2 #panel-item-list {
    display: none;
  }
#sample-panel2 #panel-section-list #panel-section-grid {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      width: 24% !important;
    }
[id*="pivot-box-"] {
  /* height: calc(100vh - 555px);*/
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
#pivot-box-grid {
  height: calc(100vh - 670px);
  width: -webkit-max-content;
  width: max-content;
  /* height: max-content;*/
}
#pivot-box-graph {
  height: calc(100vh - 670px);
}
#pivot-box-graph > div {
    /* height: -webkit-fill-available !important;*/
    /* width: -webkit-fill-available !important;*/
    height: 100% !important;
    width: 100% !important;
  }
#pivot-box-graph > div > div:not(.wj-dropdown) {
      height: 100% !important;
      width: 100% !important;
    }
.accordion-button {
  border: 1px solid #a6a6a8 !important;
  text-align: left;
}
.accordion-button.show {
    background-color: rgb(0, 157, 209);
    color: white;
  }
.accordion-button.show > div > span.right{
        display: none;
      }
.accordion-button.show > div > span.down{
        display: inline;
      }
.accordion-button.hide {
    background-color: lightgray;
    color: rgba(120, 120, 117, 1);
  }
.accordion-button.hide > div > span.right{
        display: inline;
      }
.accordion-button.hide > div > span.down{
        display: none;
      }
.wj-tabpanel>div>.wj-tabpanes {
  /* width: max-content !important;*/
  width: 100% !important;
}
.bi-option {
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.report-select-tree .accordion.wj-treeview .wj-nodelist>.wj-nodelist>.wj-node.wj-state-selected {
    background-color: rgb(0, 157, 209);
    color: white;
  }
.wj-pivotpanel #panel-section-grid .wj-flexgrid {
  max-height: inherit;
  min-height: inherit;
  height: 7.75rem;
}
.wj-pivotpanel #panel-section-grid .wj-flexgrid>div {
    height: inherit;
  }
#custom-field-editor div[wj-part="div-hdr"] {
    background-color: rgb(0, 157, 209) !important;
    color:white !important;
  }
.empty-message {
  text-align: center;
  margin-top: 10.0rem;
  font-size: 2.0rem;
}
.master-list-body {
  height: calc(100vh - 310px);
}
.master-list-body.production {
    height: calc(100vh - 360px);
  }
.master-list-body.company-popup {
    height: 40vh;
  }
.master-list-body {
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.refresh-button {
  color: rgb(0, 94, 184) !important;
  float: left;
  cursor: pointer;
  border: none;
  overflow: hidden;
  background-color: inherit;
  padding: 0rem 0rem;
}
.refresh-button [class^="material-icons"]{
    font-size: 2.0rem !important;
  }
.refresh-button:hover, .refresh-button:disabled {
    opacity: .5;
  }
.checkboxSpace {
  width :50px;
}
/* writing-mode: vertical-lr;*/
#member-detail .wj-colheaders .wj-cell.wj-header {
      /* text-align: left !important;*/
      /* vertical-align: middle !important;*/
      font-size: 0.85rem;
      overflow: visible  !important;
      white-space: normal !important;
    }
.master-popup-nav {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}
.commhTop {
  margin: 1.0rem 1.0rem 0.0rem 1.0rem;
  /* border-bottom-style: solid;*/
  /* border-bottom-width: 0.125rem;*/
  /* border-bottom-color: $blue;*/
}
.commhMenu {
  border: none !important;
  font-weight: bold;
  color: rgb(0, 157, 209) !important;
}
.commhMenu.active {
    color: white !important;
    background-color: rgb(0, 94, 184) !important;
    border-radius: 0.25rem 0.25rem 0rem 0rem !important;
  }
.commhEdit {
  margin: .0rem 1.0rem 1.0rem 1.0rem;
  border-style: solid;
  border-width: 0.125rem;
  border-color: rgb(0, 157, 209);
  background-color: white;
  height: 550px;
  overflow: auto;
}
.commhEdit> div > div > div.form-check {
    margin-top: 7px;
    margin-bottom: 7px;
  }
.systemMenuTop {
  margin: 1.0rem 1.0rem 0.0rem 1.0rem;
  /* border-bottom-style: solid;*/
  /* border-bottom-width: 0.125rem;*/
  /* border-bottom-color: $blue;*/
}
.systemMenu {
  border: none !important;
  font-weight: bold;
  color: rgb(0, 157, 209) !important;
}
.systemMenu.active {
    color: white !important;
    background-color: rgb(0, 94, 184) !important;
    border-radius: 0.25rem 0.25rem 0rem 0rem !important;
  }
.systemEdit {
  margin: .0rem 1.0rem 1.0rem 1.0rem;
  border-style: solid;
  border-width: 0.125rem;
  border-color: rgb(0, 94, 184);
  background-color: white;
  height: calc(100vh - 180px);
  overflow: auto;
}
.bizContents {
  overflow: hidden auto;
  height: calc(100vh - 260px);
}
.sysContents {
  overflow: hidden auto;
  height: calc(100vh - 260px);
}
.label-color {
  color: gray;
}
.user-list-body {
  max-height: calc(100vh - 235px);
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.user-list-body button.wj-cell-maker {
    background: transparent linear-gradient(180deg, rgb(0, 94, 184) 0%, #00478B 100%) 0% 0% no-repeat padding-box !important;
    border-color: rgb(0, 94, 184) !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    color: white !important;
  }
.user-list-body button.wj-cell-maker:hover, .user-list-body button.wj-cell-maker:disabled {
      opacity: 0.5 !important;
    }
.confirm-body {
  height: 100%;
  width: 100%;
  padding: 1rem;
  color: rgba(120, 120, 117, 1);
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(120, 120, 117, 1);
  border-radius: 4px;
}
.confirm-body.center {
    text-align: center;
  }
.confirm-body .title {
    font-size: 1.3rem;
    text-align: center;
  }
.confirm-body .warn-color {
    color: rgb(233, 83, 38);
  }
.confirm-body .confirm-sign {
    height: 100%;
    width: 100%;
    padding: 1rem;
    color: rgba(120, 120, 117, 1);
    background-color: rgba(255, 255, 143, 1);
    border: 1px solid rgba(120, 120, 117, 1);
    border-radius: 4px;
  }
#import-table {
  width: 100%;
}
#import-table th {
    background-color: #eee;
  }
#import-table th, #import-table td {
    /* &:nth-child(2) {*/
    /*   text-align: center;*/
    /*   width: 250px !important;*/
    /*   div>input {*/
    /*     transform: scale(1.5);*/
    /*   }*/
  }
#import-table th:nth-child(1), #import-table td:nth-child(1) {
      text-align: center;
      width: 50px !important;
    }
#import-table th:nth-child(1) div, #import-table td:nth-child(1) div {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
        margin-left: .6rem !important;
        margin-right: 0rem !important;
      }
#import-table th:nth-child(2), #import-table td:nth-child(2) {
      width: 150px !important;
    }
/* }*/
#import-table th:last-child, #import-table td:last-child {
      width: 150px !important;
      text-align: center;
    }
#import-table .import-button {
    /* padding: .5rem 1rem;*/
    /* background: transparent linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box !important;*/
    /* border: 1px solid #a6a6a8 !important;*/
    /* border-radius: 4px !important;*/
    /* opacity: 1 !important;*/
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
    margin: 0 0 0 0 !important;
    width: 100%;
    cursor: pointer;
  }
#import-table .import-button:disabled {
      opacity: 0.5 !important;
    }
.tkt-header {
  background-color: rgba(213, 230,242, 1);
}
.ticket-status {
  background-color: rgb(0, 157, 209) !important;
  color: white !important;
}
.ticket-status[isreadonly="true"] {
    background-color: rgb(0, 157, 209) !important;
  }
.err-list-body {
  height: calc(100vh - 600px);
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.flight-list-body > div {
  height: 230px;
}
.flight-list-body > div button.wj-btn.wj-btn-glyph.wj-right.wj-elem-dropdown {
    display: none;
  }
.tax-list-body > div {
  height: 240px;
}
.coupon-list-body > div {
  height: 100px;
}
.refund-list-body > div {
  height: 235px;
}
.emd-list-body > div {
  height: 190px;
}
.add-list-body > div {
  height: 180px;
}
.settings-list-body > div {
  height: calc(50vh - 25px);
}
.settings-list-body > div [wj-part="root"] {
    overflow-x: hidden !important;
  }
#detail-setting .wj-listbox {
  max-height: 200px;
}
#excel-tab .wj-tabpane {
  height: calc(100vh - 130px);
}
.tktListSellector {
  position: absolute;
  top: 3px;
  right: 0px;
}
.tktListSellector.report {
    right: 200px;
  }
.status-window .pane1 {
    -webkit-flex-basis: 20%;
            flex-basis: 20%;
  }
.status-window .pane2 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
  }
.status-window .pane3 {
    -webkit-flex-basis: 30%;
            flex-basis: 30%;
  }
.pax-width {
  width: 217px !important;
}
.pnr-width {
  width: 90px !important;
}
.wj-flexgrid .wj-grid-editor {
  padding: 0rem 0rem !important;
}
tr.disabled {
    background-color: #eee;
    opacity: .5;
  }
.settings-sub-header {
  color: rgb(233, 83, 38)  !important;
}
.excel-output-list-body {
  height: calc(100vh - 415px);
}
.excel-output-list-body-2 {
  height: calc(100vh - 530px);
}
.split-pane-top.full>[data-type="Resizer"] {
  /* display: none !important;*/
  height: 2px !important;
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
#timer-table {
  width: 100%;
  /* th {*/
  /*   &:nth-child(1) {*/
  /*     text-align: center;*/
  /*     width: 50px !important;*/
  /*     background-color: aqua !important;*/
  /*   }*/
  /*   &:nth-child(2) {*/
  /*     width: 150px !important;*/
  /*   }*/
  /*   // &:nth-child(2) {*/
  /*   //   text-align: center;*/
  /*   //   width: 250px !important;*/
  /*   //   div>input {*/
  /*   //     transform: scale(1.5);*/
  /*   //   }*/
  /*   // }*/
  /*   &:last-child {*/
  /*     width: 150px !important;*/
  /*     text-align: center;*/
  /*   }*/
}
#timer-table tr.gray-row {
      background-color: #eee;
    }
#timer-table tr:nth-child(1) th {
        background-color: #eee;
        vertical-align: middle;
      }
#timer-table tr:nth-child(1) th:nth-child(1) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(2) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(3) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(4) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(5) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(6) {
          text-align: center;
        }
#timer-table tr:nth-child(1) th:nth-child(7) {
          text-align: center;
        }
#timer-table tr:nth-child(2) th {
        background-color: #eee;
        vertical-align: middle;
      }
#timer-table tr:nth-child(2) th:nth-child(1) {
          text-align: center;
        }
#timer-table tr:nth-child(2) th {
        vertical-align: middle;
}
#timer-table tr:nth-child(2) th:nth-child(2) {
          text-align: center;
        }
#timer-table tr td.edit-row {
        background-color: #FFE699;
      }
#timer-table tr td:nth-child(1) {
        text-align: center;
        width: 50px !important;
      }
#timer-table tr td:nth-child(2) {
        text-align: left;
        width: 150px !important;
      }
#timer-table tr td:nth-child(3) {
        text-align: center;
        width: 150px !important;
      }
#timer-table tr td:nth-child(4) {
        text-align: center;
        width: 130px !important;
      }
#timer-table tr td:nth-child(5) {
        text-align: center;
        width: 150px !important;
      }
#timer-table tr td:nth-child(6) {
        text-align: center;
        width: 160px !important;
      }
#timer-table tr td:nth-child(7) {
        text-align: center;
        width: 160px !important;
      }
#timer-table tr td:nth-child(8) {
        text-align: center;
        width: auto !important;
      }
#timer-table tr td:nth-child(9) {
        text-align: center;
        width: 130px !important;
      }
#timer-table tr td:nth-child(10) {
        text-align: center;
        width: 150px !important;
      }
#timer-table tbody tr.selected{
      background-color: rgba(0, 157, 209, .5);
    }
/* }*/
#timer-table .import-button {
    /* padding: .5rem 1rem;*/
    /* background: transparent linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box !important;*/
    /* border: 1px solid #a6a6a8 !important;*/
    /* border-radius: 4px !important;*/
    /* opacity: 1 !important;*/
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
    margin: 0 0 0 0 !important;
    width: 100%;
    cursor: pointer;
  }
#timer-table .import-button:disabled {
      opacity: 0.5 !important;
    }
.err-list-body {
  height: calc(100vh - 600px);
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.modal-timer {
  /* height: 90vh;*/
  width: 90vw;
}
.modal-timer.excel {
    width: 95vw;
  }
.process-list-body {
  height: calc(100vh - 340px);
}
.process-list-body.menu {
    height: calc(100vh - 285px);
  }
.process-list-body {
  display: -webkit-flex;
  display: flex;
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
.process-list-body .wj-cell:not(.wj-state-multi-selected) .link-text {
      color: rgb(0, 157, 209);
      text-decoration: underline;
      -webkit-text-decoration-color: rgb(0, 157, 209);
              text-decoration-color: rgb(0, 157, 209);
      cursor: pointer;
    }
.process-cell-success {
  background-color: lightcyan !important;
}
.process-cell-failure {
  background-color: lightcoral !important;
}
/* #report-output {*/
/*   div {*/
/*     margin-right: 1.0rem;*/
/*   }*/
/*   .form-check {*/
/*     display: block;*/
/*     margin-bottom: 100px;*/
/*   }*/
/* }*/
.step0 {
  height: 100%;
  width: 100%;
  /* display: flex;*/
  background-color: rgba(0, 157, 209, .5);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.step0-title {
  font-size: 1.5rem;
  margin-bottom: 1.0rem;
}
.step0-body {
  margin-bottom: 5.0rem;
}
.step4-body {
  margin-bottom: 2.0rem;
}
.step-circle {
  width: 90px;
  height: 90px;
  display:table-cell;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(247, 247, 250, 1);
  color: black;
  font-size: 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.step-circle.active {
    background-color: rgb(0, 94, 184);
    color: white;
  }
.step-arrow {
  width: 115px;
  height: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: end;
          justify-content: end;
  text-align: center;
  color: rgba(120, 120, 117, 1);
  font-size: 1.2rem;
}
.step-arrow .minus {
    margin-left: -8px;
  }
.step-arrow .arrow {
    margin-left: -25px;
  }
.step-error {
  height: 100%;
  max-width: 90vw;
  text-overflow: ellipsis;
  padding: 1rem;
  color: rgba(255, 10, 10, 1);
  background-color: rgba(255, 10, 10, 0.1);
  border: 1px solid rgba(255, 10, 10, 1);
  border-radius: 4px;
}
.step-body {
  height: 100%;
  width: 100%;
  padding: 1rem;
  color: rgba(120, 120, 117, 1);
  background-color: rgba(247, 247, 250, 1);
  border: 1px solid rgba(120, 120, 117, 1);
  border-radius: 4px;
}
.step-body.center {
    text-align: center;
  }
.step-attention {
  color: rgba(255, 10, 10, 1);
  font-size: 1.15rem;
}
.small-button {
  font-size: .7rem;
}
.migration-upload-button{
  background: transparent linear-gradient(180deg, rgb(0, 94, 184) 0%, #00478B 100%) 0% 0% no-repeat padding-box !important;
  border-color: rgb(0, 94, 184) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: white !important;
}
.migration-upload-button:hover, .migration-upload-button:disabled {
    opacity: 0.5 !important;
  }
.migration-upload-button>a {
    color: white !important;
  }
.migration-upload-filename {
  color: rgb(0, 157, 209);
}